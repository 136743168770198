import {
  anyToFWRegisterRecord,
  FWRegisterFormat,
  FWRegisterRecord,
  FWRegisterRecordTargetEnum
} from "foodware-lib";
import React, { useEffect, useState } from "react";

import { IonLoading } from "@ionic/react";

import { FWBackendApi } from "../services/FWBackendApi";
import RegisterRecordCollectPeople from "./collect/RegisterRecordCollectPeople";
import RegisterRecordCollectProductReception from "./collect/RegisterRecordCollectProducts";

export interface RegisterRecordCollectWrapperProps {
  readOnly?: boolean;
  registerRecordId?: number;
  registerFormat?: FWRegisterFormat;
  closeHandler?: () => void;
  newRegisterRecordEntryCreateHandler?: (
    registerData: Record<string, unknown>
  ) => Promise<unknown>;
}

export const RegisterRecordCollectWrapper: React.FC<
  RegisterRecordCollectWrapperProps
> = ({
  readOnly = false,
  registerRecordId,
  registerFormat,
  closeHandler,
  newRegisterRecordEntryCreateHandler,
}) => {
  console.log("Wrapper");
  console.log(registerRecordId);

  const {
    data: registerRecordRaw,
    isFetching: recordIsFetching,
    isError: recordIsError,
    isSuccess: recordIsSuccess,
    refetch,
  } = FWBackendApi.useGetRegisterRecordByIdQuery(registerRecordId, {
    skip: !registerRecordId,
  });

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (recordIsError) {
      setIsLoading(false);
    } else if (recordIsFetching) {
      setIsLoading(true);
    } else if (recordIsSuccess) {
      setIsLoading(false);
    }
  }, [recordIsFetching, recordIsSuccess, recordIsError]);

  const registerRecord: FWRegisterRecord | undefined =
    registerRecordRaw && anyToFWRegisterRecord(registerRecordRaw);

  const registerRecordCollectProps = {
    readOnly,
    registerRecord,
    registerFormat,
    closeHandler,
    newRegisterRecordEntryCreateHandler,
    refetch,
  };

  return (
    <>
      {/* TODO: add skeleton page to show during loading */}
      <IonLoading backdropDismiss={false} isOpen={isLoading}></IonLoading>
      {registerFormat && registerRecord && recordIsSuccess && (
        <>
          {registerFormat.target === FWRegisterRecordTargetEnum.PEOPLE && (
            <RegisterRecordCollectPeople {...registerRecordCollectProps} />
          )}
          {registerFormat.target ===
            FWRegisterRecordTargetEnum.PRODUCT_RECEPTION && (
            <RegisterRecordCollectProductReception
              {...registerRecordCollectProps}
            />
          )}
        </>
      )}
    </>
  );
};

export default RegisterRecordCollectWrapper;
