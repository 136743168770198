/* eslint-disable react/no-unescaped-entities */

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import { close } from "ionicons/icons";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";

export interface FWRegisterData {
  username: string;
  email: string;
  password1: string;
  password2: string;
  firstname: string;
  lastname: string;
}

export interface FWRegisterProps {
  className?: string;
  pageClassName?: string;
  closeHandler?: () => void;
  registerHandler?: (registerData: FWRegisterData) => void;
  loginClickHandler?: () => void;
}

const defaultCloseHandler = () => {
  console.log("Default close handler");
};

const defaultRegisterHandler = (registerData: FWRegisterData) => {
  console.log("Default login handler, got register data:");
  console.log(registerData);
};

const defaultLoginClickHandler = () => {
  console.log("Default login click handler");
};

const formSchema = yup.object({
  firstname: yup.string().required("Ingrese su nombre."),
  lastname: yup.string().required("Ingrese su apellido."),
  email: yup.string().email().required("Ingrese su email."),
  password1: yup.string().required("Ingrese su contraseña."),
  password2: yup
    .string()
    .test(
      "passwords-match",
      "Las contraseñas deben coincidir.",
      function (value) {
        return this.parent.password1 === value;
      }
    ),
});

export const FWRegister: React.FC<FWRegisterProps> = ({
  className,
  pageClassName,
  closeHandler = defaultCloseHandler,
  registerHandler = defaultRegisterHandler,
  loginClickHandler = defaultLoginClickHandler,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleRegisterCall = (data: FieldValues) => {
    console.log("Handling register button click; calling registerHandler");
    console.log(data);

    registerHandler({
      username: data["email"],
      email: data["email"],
      password1: data["password1"],
      password2: data["password2"],
      firstname: data["firstname"],
      lastname: data["lastname"],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={closeHandler}>
              <IonIcon slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className={`ion-padding ion-text-center ${pageClassName}`}
        fullscreen
        scrollY={false}
      >
        <IonCard>
          <IonCardContent>
            <form
              onSubmit={handleSubmit(handleRegisterCall)}
              className="ion-text-center"
            >
              <IonGrid className={className}>
                <IonRow style={{ paddingTop: "28%" }}>
                  <IonText
                    style={{
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "130%",
                    }}
                  >
                    Crear una cuenta
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">Nombre</IonLabel>
                      <IonInput
                        type="text"
                        {...register("firstname")}
                        value={getValues("firstname")}
                        onIonChange={(e) =>
                          e.detail.value &&
                          setValue("firstname", e.detail.value)
                        }
                      ></IonInput>
                      <ErrorMessage
                        className="ion-text-left"
                        errors={errors}
                        name="firstname"
                        as={<div style={{ color: "red" }} />}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">Apellido</IonLabel>
                      <IonInput
                        type="text"
                        {...register("lastname")}
                        value={getValues("lastname")}
                        onIonChange={(e) =>
                          e.detail.value && setValue("lastname", e.detail.value)
                        }
                      ></IonInput>
                      <ErrorMessage
                        className="ion-text-left"
                        errors={errors}
                        name="lastname"
                        as={<div style={{ color: "red" }} />}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">Email</IonLabel>
                      <IonInput
                        type="email"
                        {...register("email")}
                        value={getValues("email")}
                        onIonChange={(e) =>
                          e.detail.value && setValue("email", e.detail.value)
                        }
                      ></IonInput>
                      <ErrorMessage
                        className="ion-text-left"
                        errors={errors}
                        name="email"
                        as={<div style={{ color: "red" }} />}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">Contraseña</IonLabel>
                      <IonInput
                        type="password"
                        {...register("password1")}
                        value={getValues("password1")}
                        onIonChange={(e) =>
                          e.detail.value &&
                          setValue("password1", e.detail.value)
                        }
                      ></IonInput>
                      <ErrorMessage
                        className="ion-text-left"
                        errors={errors}
                        name="password1"
                        as={<div style={{ color: "red" }} />}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">
                        Repite la contraseña
                      </IonLabel>
                      <IonInput
                        type="password"
                        {...register("password2")}
                        value={getValues("password2")}
                        onIonChange={(e) =>
                          e.detail.value &&
                          setValue("password2", e.detail.value)
                        }
                      ></IonInput>
                      <ErrorMessage
                        className="ion-text-left"
                        errors={errors}
                        name="password2"
                        as={<div style={{ color: "red" }} />}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      className={"register-button"}
                      expand="block"
                      type="submit"
                    >
                      Registrarme
                    </IonButton>
                    <p style={{ fontSize: "small", textAlign: "left" }}>
                      ¿Ya tienes una cuenta?{" "}
                      <a onClick={loginClickHandler}>Ingresar</a>
                    </p>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
