/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
/* App.css */
import "./App.css";

/* fp-ts */
import { isSome, none, Option, some } from "fp-ts/Option";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import {
  IonAlert,
  IonApp,
  IonLoading,
  IonModal,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Custom Foodware components */
import {
  FWLogin,
  FWLoginData,
  FWLoginProps,
} from "./components/FWLogin/FWLogin";
import {
  FWRegister,
  FWRegisterData,
  FWRegisterProps,
} from "./components/FWRegister/FWRegister";
import { OfferCreatedNotificationData } from "./features/notifications/notificationsActions";
import {
  NotificationData,
  NotificationUserFlow,
} from "./features/notifications/types";
import {
  loginUser,
  LoginUserData,
  registerUser,
  RegisterUserData,
} from "./features/user/userActions";
/* Store management */
import { useAppDispatch, useAppSelector } from "./hooks";
import AppTabs from "./pages/AppTabs";
import Init from "./pages/Init";
import NotificationPage from "./pages/NotificationPage";
import RegisterSuccess from "./pages/RegisterSuccess";
import { RootState } from "./store";

setupIonicReact({ mode: "md" });

const App: React.FC = () => {
  /* ******************************************************************************/
  /* USER-RELATED STATE */
  const {
    loading,
    loggedIn,
    error: stateError,
    loginResponse,
    registerSuccess,
    registerResponse,
  } = useAppSelector((state: RootState) => state.user);

  /* NOTIFICATIONS-RELATED STATE */
  const { offerCreatedNotification } = useAppSelector(
    (state: RootState) => state.notifications
  );

  const dispatch = useAppDispatch();

  /* ******************************************************************************/
  /* USER LOGIN */

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const performLogin = async ({ username, email, password }: FWLoginData) => {
    const data: LoginUserData = { username, email, password };
    setLoadingMessage("Iniciando sesión...");
    dispatch(loginUser(data));
  };

  const loginModal = useRef<HTMLIonModalElement>(null);
  const fwLoginProps: FWLoginProps = {
    className: "fw-login",
    pageClassName: "fw-login-page",
    closeHandler: () => {
      setLoginModalOpen(false);
    },
    loginHandler: performLogin,
  };

  /* ******************************************************************************/
  /* USER REGISTRATION */

  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);

  const registerSuccessModal = useRef<HTMLIonModalElement>(null);
  const [isRegisterSuccessModalOpen, setIsRegisterSuccessModalOpen] =
    useState(false);

  const [registerEmail, setRegisterEmail] = useState<string>("");

  const performRegister = async ({
    username,
    email,
    password1,
    password2,
    firstname,
    lastname,
  }: FWRegisterData) => {
    console.log("Register user...");
    const data: RegisterUserData = {
      username,
      email,
      password1,
      password2,
      firstname,
      lastname,
    };
    setRegisterEmail(email);
    setLoadingMessage("Registrando usuario...");
    dispatch(registerUser(data));
  };

  const registerModal = useRef<HTMLIonModalElement>(null);
  const fwRegisterProps: FWRegisterProps = {
    className: "fw-login",
    pageClassName: "fw-register-page",
    registerHandler: performRegister,
    closeHandler: () => {
      console.log("Close Handler");
      setRegisterModalOpen(false);
      registerModal.current?.dismiss();
    },
  };

  const initModal = useRef<HTMLIonModalElement>(null);
  const [isInitModalOpen, setIsInitModalOpen] = useState(!loggedIn);

  const errorPrefixTable: { [key: string]: string } = {
    username: "Nombre de Usuario",
    firstname: "Nombres",
    lastname: "Apelllidos",
    email: "Email",
    password1: "Password",
    password2: "Repetir Password",
  };

  const errorMessageBuilder = (key: string, message: string) => {
    if (key in errorPrefixTable) {
      const prefix = errorPrefixTable[key];
      return `${prefix}: ${message}`;
    } else if (key === "non_field_errors") {
      console.log("non_field_errors");
      return message;
    } else {
      return message;
    }
  };

  /* ******************************************************************************/
  /* NOTIFICATIONS */
  const notificationModal = useRef<HTMLIonModalElement>(null);
  const [isNotificationModalOpen, setNotificationModalOpen] =
    useState<boolean>(false);
  const [notificationUserFlow, setNotificationUserFlow] =
    useState<Option<NotificationUserFlow>>(none);
  const [notificationData, setNotificationData] =
    useState<Option<NotificationData>>(none);

  const clearNotificationState = () => {
    setNotificationData(none);
    setNotificationUserFlow(none);
    setNotificationModalOpen(false);
    notificationModal.current?.dismiss();
  };

  useEffect(() => {
    if (isSome(offerCreatedNotification)) {
      const refinedNotificationData =
        offerCreatedNotification.value as OfferCreatedNotificationData;
      if (refinedNotificationData.success) {
        setNotificationUserFlow(some(NotificationUserFlow.OFFER_CREATED));
        setNotificationData(some(refinedNotificationData));
        setNotificationModalOpen(true);
      }
    }
  }, [offerCreatedNotification]);

  /* ******************************************************************************/
  /* COMMON/SHARED ELEMENTS */

  const [errorAlertTitle, setErrorAlertTitle] = useState("");
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("Cargando...");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    console.log(`loggedIn: ${loggedIn}`);

    if (loggedIn) {
      setIsInitModalOpen(!loggedIn);
      setLoginModalOpen(!loggedIn);
      setRegisterModalOpen(!loggedIn);
    } else {
      setIsInitModalOpen(true);
    }

    if (
      isRegisterModalOpen &&
      isSome(registerSuccess) &&
      registerSuccess.value
    ) {
      setIsRegisterSuccessModalOpen(true);
    }

    if (isSome(stateError) && stateError.value) {
      /* is this a registration error or a login error? */
      if (isSome(registerSuccess) && !registerSuccess.value) {
        /* registration error */
        setErrorAlertTitle("Error al registrar usuario");

        const registerErrorMessage = isSome(registerResponse)
          ? Object.keys(registerResponse.value)
              .map(
                (k) =>
                  "<li>" +
                  errorMessageBuilder(
                    k,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (registerResponse.value as any)[k][0] || ""
                  ) +
                  "</li>"
              )
              .join("")
          : "Error desconocido al registrar usuario";

        setErrorAlertMessage(registerErrorMessage);
      } else {
        /* login error */
        setErrorAlertTitle("Error al iniciar sesión usuario");

        const loginErrorMessage = isSome(loginResponse)
          ? Object.keys(loginResponse.value)
              .map(
                (k) =>
                  "<li>" +
                  errorMessageBuilder(
                    k,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (loginResponse.value as any)[k][0] || ""
                  ) +
                  "</li>"
              )
              .join("")
          : "Error desconocido al iniciar sesión";

        setErrorAlertMessage(loginErrorMessage);
      }

      setShowErrorAlert(true);
    }

    console.log(registerResponse);
  }, [loggedIn, stateError, registerSuccess]);

  return (
    <IonApp>
      <IonLoading isOpen={loading} message={loadingMessage} />

      <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        header={errorAlertTitle}
        subHeader=""
        message={errorAlertMessage}
        buttons={[
          {
            text: "OK",
            role: "confirm",
          },
        ]}
      />

      <IonModal
        ref={registerSuccessModal}
        keepContentsMounted={true}
        canDismiss={!isRegisterSuccessModalOpen}
        isOpen={isRegisterSuccessModalOpen}
      >
        <RegisterSuccess email={registerEmail} />
      </IonModal>

      <IonModal
        ref={notificationModal}
        keepContentsMounted={true}
        canDismiss={!isNotificationModalOpen}
        isOpen={isNotificationModalOpen}
      >
        {isSome(notificationData) && isSome(notificationUserFlow) && (
          <NotificationPage
            closeHandler={clearNotificationState}
            userFlow={notificationUserFlow.value}
            notificationData={notificationData.value}
          />
        )}
      </IonModal>

      <IonModal
        ref={initModal}
        keepContentsMounted={true}
        canDismiss={!isInitModalOpen}
        isOpen={isInitModalOpen}
      >
        <Init
          openLoginHandler={() => setLoginModalOpen(true)}
          openRegisterHandler={() => setRegisterModalOpen(true)}
        ></Init>
      </IonModal>

      <IonModal
        ref={loginModal}
        keepContentsMounted={true}
        canDismiss={!isLoginModalOpen}
        isOpen={isLoginModalOpen}
      >
        <FWLogin {...fwLoginProps}></FWLogin>
      </IonModal>

      <IonModal
        ref={registerModal}
        keepContentsMounted={true}
        canDismiss={!isRegisterModalOpen}
        isOpen={isRegisterModalOpen}
      >
        <FWRegister {...fwRegisterProps}></FWRegister>
      </IonModal>

      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/tabs" render={() => <AppTabs />} />
          <Route exact path="/" render={() => <Redirect to="/tabs/home" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
