import { Option } from "fp-ts/Option";

import { createAsyncThunk } from "@reduxjs/toolkit";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_BASE_URL } from "../../services/common";
import { AppDispatch } from "../../store";

/* ******************************************************************************/
/* USER LOGIN */
export interface LoginUserData {
  username: string;
  email: string;
  password: string;
}

interface LoginResponseData {
  status: number;
  body: object;
  access_token?: Option<string>;
  refresh_token?: Option<string>;
  user?: Option<object>;
}

let LOGIN_URL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://`;
LOGIN_URL += `${process.env.REACT_APP_BACKEND_URL}:`;
LOGIN_URL += `${process.env.REACT_APP_BACKEND_PORT}/`;
LOGIN_URL += `${process.env.REACT_APP_BACKEND_LOGIN_PATH}`;

export const loginUser = createAsyncThunk<
  LoginResponseData,
  LoginUserData,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  "user/login",
  async ({ username, email, password }: LoginUserData, { rejectWithValue }) => {
    try {
      // * First login with tenant-unaware user.
      const response = fetch(LOGIN_URL, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          email: email,
          password: password,
        }),
      });

      const result = await response.then((r) => {
        return r.text().then((data) => {
          //! CockroachDB IDs are too large for plain JSON deserialization.
          //! As a workaround we capture the value and turn it into a string.
          //TODO: we will not use CockroachDB as it is too slow.
          const pkRegexp = /"pk"\s*:\s*(\d+)\s*,/;
          const pkRegexpResult = data.match(pkRegexp);
          if (pkRegexpResult) {
            const pk = pkRegexpResult[1];
            const pkString = `"pk": "${pk}",`;
            data = data.replace(pkRegexp, pkString);
          }
          const r_json = JSON.parse(data);
          console.log("r_json");
          console.log(r_json);

          // * Then try to retrieve FWUserProfile, if this fails login is unsuccessful.
          const profile_response = fetch(
            `${API_BASE_URL}profiles/byUserId/${r_json.user.pk}/`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${r_json.access_token}`,
              },
            }
          );

          return profile_response.then((r) => {
            console.log("Got profile");
            console.log(r);
            if (r.ok) {
              // * Check if FWUserProfile can access the registers app
              const result = r.json().then((r_profile) => {
                console.log("profile json");
                console.log(r_profile);
                if (r_profile?.can_access_registers) {
                  return { status: r.status, body: r_json };
                } else {
                  return { status: 403, body: {} };
                }
              });
              return result;
            } else {
              return { status: 400, body: {} };
            }
          });
        });
      });

      return result;
    } catch (e: any) {
      return rejectWithValue((e?.message || "error") as string);
    }
  }
);

/* ******************************************************************************/
/* USER LOGOUT */

let LOGOUT_URL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://`;
LOGOUT_URL += `${process.env.REACT_APP_BACKEND_URL}:`;
LOGOUT_URL += `${process.env.REACT_APP_BACKEND_PORT}/`;
LOGOUT_URL += `${process.env.REACT_APP_BACKEND_LOGOUT_PATH}`;

console.log(`logout url: ${LOGOUT_URL}`);

export const logoutUser = createAsyncThunk<
  { status: number },
  any,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>("user/logout", async (_, { rejectWithValue }) => {
  try {
    const response = fetch(LOGOUT_URL, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.then((r) =>
      r.json().then(() => ({ status: r.status }))
    );

    return result;
  } catch (e: any) {
    return rejectWithValue((e?.message || "error") as string);
  }
});

/* ******************************************************************************/
/* USER REGISTRATION */

export interface RegisterUserData {
  username: string;
  email: string;
  password1: string;
  password2: string;
  firstname: string;
  lastname: string;
}

interface RegisterResponseData {
  status: number;
  body: object;
  access_token?: Option<string>;
  refresh_token?: Option<string>;
  user?: Option<object>;
}

let REGISTER_URL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://`;
REGISTER_URL += `${process.env.REACT_APP_BACKEND_URL}:`;
REGISTER_URL += `${process.env.REACT_APP_BACKEND_PORT}/`;
REGISTER_URL += `${process.env.REACT_APP_BACKEND_REGISTER_PATH}`;

export const registerUser = createAsyncThunk<
  RegisterResponseData,
  RegisterUserData,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  "user/register",
  async (
    {
      username,
      email,
      password1,
      password2,
      firstname,
      lastname,
    }: RegisterUserData,
    { rejectWithValue }
  ) => {
    try {
      const response = fetch(REGISTER_URL, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          email: email,
          password1: password1,
          password2: password2,
          firstname: firstname,
          lastname: lastname,
        }),
      });

      const result = await response.then((r) =>
        r.json().then((data) => ({ status: r.status, body: data }))
      );

      return result;
    } catch (e: any) {
      return rejectWithValue((e?.message || "error") as string);
    }
  }
);

export interface TokenRefreshData {
  access: string;
  refresh?: string;
}

export const tokenReceived = createAsyncThunk<
  TokenRefreshData,
  TokenRefreshData,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>("user/token_received", async (tokenData: TokenRefreshData, _) => {
  return tokenData;
});
