import { none, Option, some } from "fp-ts/Option";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";

import { loginUser, logoutUser, registerUser, tokenReceived } from "./userActions";

interface UserState {
  loggedIn: boolean;
  loading: boolean;
  success: boolean;
  error: Option<string>;
  loginResponse: Option<any>;
  userInfo: Option<any>;
  userAccessToken: Option<string>;
  userRefreshToken: Option<string>;
  registerSuccess: Option<boolean>;
  registerResponse: Option<any>;
}

const initialState: UserState = {
  loggedIn: false,
  loading: false,
  success: false,
  error: none,
  loginResponse: none,
  userInfo: none,
  userAccessToken: none,
  userRefreshToken: none,
  registerSuccess: none,
  registerResponse: none,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* *********************** UPDATE ACCESS TOKEN********** */
    builder.addCase(tokenReceived.fulfilled, (state, { payload }) => {
      const newAccessToken = payload.access ? some(payload.access) : none;
      state.userAccessToken = newAccessToken;

      const newRefreshToken = payload.refresh ? some(payload.refresh) : none;
      state.userRefreshToken = newRefreshToken;
    });

    /* *********************** LOGIN *********************** */
    builder.addCase(loginUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = none;
      state.loggedIn = false;

      state.registerSuccess = none;
      state.registerResponse = none;
    });

    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      console.log("Fulfilled");
      state.loading = false;

      console.log("payload: ");
      console.log(payload);

      // Correct login responses are expected to fill all fields from LoginResponseData.
      if ([200].includes(payload.status)) {
        state.success = true;
        state.userAccessToken = some((payload?.body as any)["access_token"]);
        state.userRefreshToken = some((payload?.body as any)["refresh_token"]);
        state.userInfo = some((payload?.body as any)["user"]);
        state.loggedIn = true;
      } else {
        state.success = false;
        state.loggedIn = false;
        state.error = some("Error iniciando sesión.");
        state.loginResponse = some(payload?.body);
      }
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      console.log("Rejected");
      console.log("Rejected payload: ");
      console.log(payload);
      state.loading = false;
      state.error = payload
        ? some("Error interno intentando iniciar sesión.")
        : some(payload as string);
      state.loggedIn = false;
    });
    /* *********************** LOGOUT *********************** */
    builder.addCase(logoutUser.pending, (state) => {
      console.log("Pending");
      state.loading = true;
      state.error = none;
    });

    builder.addCase(logoutUser.fulfilled, (state, { payload }) => {
      console.log("Fulfilled");
      state.loading = false;

      console.log("payload: ");
      console.log(payload);

      // Correct login responses are expected to fill all fields from LoginResponseData.
      if ([200].includes(payload.status)) {
        state.success = true;
      } else {
        state.success = false;
        state.error = some("Error cerrando sesión.");
      }
      state.userAccessToken = none;
      state.userRefreshToken = none;
      state.userInfo = none;
      state.loggedIn = false;
    });
    builder.addCase(logoutUser.rejected, (state, { payload }) => {
      console.log("Rejected");
      console.log("Rejected payload: ");
      console.log(payload);
      state.loading = false;
      state.error = payload
        ? some("Error interno intentando cerrar sesión.")
        : some(payload as string);
    });

    /* *********************** REGISTER *********************** */
    builder.addCase(registerUser.pending, (state) => {
      console.log("Register User Pending");
      state.loading = true;
      state.error = none;
      state.registerSuccess = none;
      state.registerResponse = none;
    });

    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      console.log("Register User Fulfilled");
      console.log(payload);

      state.loading = false;

      if ([200, 201].includes(payload.status)) {
        console.log("Success");
        state.error = none;
        state.registerSuccess = some(true);
        state.registerResponse = some(payload.body);
      } else {
        console.log("Error");
        state.error = some("Error en el registro del usuario");
        state.registerSuccess = some(false);
        state.registerResponse = some(payload.body);
      }
    });

    builder.addCase(registerUser.rejected, (state, { payload }) => {
      console.log("Rejected");
      console.log("Rejected payload: ");
      console.log(payload);

      state.loading = false;
      state.error = payload
        ? some("Error interno intentando registrar usuario.")
        : some(payload as string);
      state.registerSuccess = some(false);
      state.registerResponse = none;
    });
  },
});

export default userSlice.reducer;
