import { Mutex } from "async-mutex";
import * as O from "fp-ts/Option";

import { BaseQueryApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query";

import {
  logoutUser,
  tokenReceived,
  TokenRefreshData
} from "../features/user/userActions";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import type { RootState } from "../store";

export const defaultPrepareHeadersAuth = (
  headers: Headers,
  {
    getState,
  }: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
) => {
  const token = (getState() as RootState).user.userAccessToken;
  // If we have a token set in state, let's assume that we should be passing it.
  if (O.isSome(token)) {
    headers.set("Authorization", `Bearer ${token.value}`);
  }
};

export const BACKEND_DRF_BASE_PATH =
  process.env.REACT_APP_BACKEND_API_BASE_PATH || "drf";

export const BACKEND_NINJA_BASE_PATH =
  process.env.REACT_APP_BACKEND_NINJA_BASE_PATH || "api";

export let BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://`;
BACKEND_BASE_URL += `${process.env.REACT_APP_BACKEND_URL}:`;
BACKEND_BASE_URL += `${process.env.REACT_APP_BACKEND_PORT}`;

export const API_BASE_URL = `${BACKEND_BASE_URL}/${BACKEND_DRF_BASE_PATH}/`;

const BACKEND_AUTH_BASE_PATH = process.env.REACT_APP_BACKEND_AUTH_BASE_PATH;
export const AUTH_BASE_URL = `${BACKEND_BASE_URL}/${BACKEND_AUTH_BASE_PATH}`;

const refreshBaseQuery = fetchBaseQuery({
  baseUrl: AUTH_BASE_URL,
});

const mutex = new Mutex();

const checkRefreshResult = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshResult: any
): refreshResult is { data: TokenRefreshData } => {
  return (
    !!refreshResult &&
    typeof refreshResult === "object" &&
    "data" in refreshResult &&
    "access" in refreshResult["data"]
  );
};

export const makeBaseQueryWithReauthAndRetry = (
  targetQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
) => {
  return retry(makeBaseQueryWithReauth(targetQuery), {
    maxRetries: 3,
  });
};

export const makeBaseQueryWithReauth = (
  targetQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
) => {
  return async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    // eslint-disable-next-line @typescript-eslint/ban-types
    extraOptions: {}
  ) => {
    // Avoid race conditions when refreshing the tokens
    await mutex.waitForUnlock();

    let result = await targetQuery(args, api, extraOptions);
    if (
      result.error &&
      (result.error.status === 401 || result.error.status === 403)
    ) {
      if (!mutex.isLocked()) {
        // If nobody else is using the mutex,
        // acquire to refresh the token.
        const release = await mutex.acquire();

        try {
          // try to get a new token
          const refreshToken = (api.getState() as RootState).user
            .userRefreshToken;
          console.log("User refresh token:");
          console.log(refreshToken);

          const refreshResult: unknown = await refreshBaseQuery(
            {
              url: "/token/refresh/",
              method: "POST",
              body: {
                refresh: O.isSome(refreshToken) ? refreshToken.value : null,
              },
            },
            api,
            extraOptions
          );

          console.log("refresh result");
          console.log(refreshResult);

          // Dynamically check proper format and data
          if (checkRefreshResult(refreshResult)) {
            // Update the new access and refresh tokens
            api.dispatch(
              tokenReceived({
                access: refreshResult.data["access"],
                refresh: refreshResult.data["refresh"],
              })
            );
            // Retry the initial query
            result = await targetQuery(args, api, extraOptions);
          } else {
            // If everything else fails, logout the user
            console.error("Refresh result failed dynamic check.");
            console.error(checkRefreshResult);
            api.dispatch(logoutUser({}));
          }
        } finally {
          release();
        }
      } else {
        // Somebody else updated the tokens and released the mutex
        await mutex.waitForUnlock();
        result = await targetQuery(args, api, extraOptions);
      }
    }
    return result;
  };
};
