import "./RegisterSuccess.css";

import React from "react";

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonToolbar
} from "@ionic/react";

const RegisterSuccess: React.FC<{ email: string }> = ({ email }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent
        className={"ion-padding ion-text-center register-success-page"}
        fullscreen
        scrollY={false}
      >
        <IonGrid className={"ion-text-center"}>
          <IonRow
            style={{
              paddingTop: "28%",
            }}
          >
            <IonCol>
              <IonText
                style={{
                  fontWeight: 700,
                  fontSize: "30px",
                  lineHeight: "130%",
                }}
              >
                Revisa tu email
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonImg
              src="assets/images/register_mail.png"
              style={{ margin: "auto", width: "50%" }}
            ></IonImg>
          </IonRow>
          <IonRow>
            <IonCol>
              Hemos enviado un correo de confirmación a{" "}
              <IonText
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "125%",
                }}
              >
                {email}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton className="login-button" expand="block" routerLink="/">
                Regresa al inicio
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RegisterSuccess;
