import { none, Option, some } from "fp-ts/Option";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";

import {
  offerCreatedNotification,
  OfferCreatedNotificationData
} from "./notificationsActions";
import { NotificationUserFlow } from "./types";

interface NotificationState {
  offerCreatedNotification: Option<OfferCreatedNotificationData>;
}

const initialState: NotificationState = {
  offerCreatedNotification: none,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      offerCreatedNotification.fulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.offerCreatedNotification = some(payload);
        }
      }
    );
  },
});

export default notificationSlice.reducer;

export const notifyOfferCreated = (dispatch: any, offerId: number) => {
  dispatch(
    offerCreatedNotification({
      success: true,
      userFlow: NotificationUserFlow.OFFER_CREATED,
      offerId,
    })
  );
};
