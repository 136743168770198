/* eslint-disable @typescript-eslint/no-unused-vars */
import { archiveOutline, home, readerOutline } from "ionicons/icons";
import React from "react";
import { Route } from "react-router-dom";

import {
  IonContent,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from "@ionic/react";

import About from "./About";
import Archived from "./Archived";
import Home from "./Home";
import Notifications from "./Notifications";
import Registers from "./Registers";
import Terms from "./Terms";
import Tutorials from "./Tutorials";

const AppTabs: React.FC = () => {
  return (
    <IonContent>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/tabs/home" render={() => <Home />} />
          <Route exact path="/tabs/registers" render={() => <Registers />} />
          <Route exact path="/tabs/archived" render={() => <Archived />} />

          {/* <Route exact path="/tabs/archived" render={() => <Archived />} /> */}

          {/* <Route
            exact
            path="/tabs/home/tutoriales"
            render={() => <Tutorials />}
          /> */}
          {/* <Route exact path="/tabs/home/about" render={() => <About />} />
          <Route exact path="/tabs/home/terms" render={() => <Terms />} /> */}

          {/* <Route exact path="/tabs/offers" render={() => <MyOffers />} /> */}
          {/* <Route
            exact
            path="/tabs/offers/byId/:id"
            render={() => <OfferWrapper />}
          /> */}
          {/* <Route exact path="/tabs/quotes" render={() => <Quotes />} /> */}
          {/* <Route
            exact
            path="/tabs/notifications"
            render={() => <Notifications />}
          /> */}
          {/* <Route exact path="/tabs/profile" render={() => <Profile />} /> */}
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton className="no-padding-end" tab="home" href="/tabs/home">
            <IonIcon icon={home} />
            <IonLabel>Inicio</IonLabel>
          </IonTabButton>

          <IonTabButton
            className="no-padding-end"
            tab="registers"
            href="/tabs/registers"
          >
            <IonIcon icon={readerOutline} />
            <IonLabel>Registros Activos</IonLabel>
          </IonTabButton>

          <IonTabButton
            className="no-padding-end"
            tab="archived"
            href="/tabs/archived"
          >
            <IonIcon icon={archiveOutline} />
            <IonLabel>Archivados</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonContent>
  );
};

export default AppTabs;
