/* eslint-disable @typescript-eslint/no-unused-vars */
import "swiper/swiper.min.css";
import "swiper/css/autoplay";
import "swiper/css/keyboard";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/zoom";
import "@ionic/react/css/ionic-swiper.css";
import "./Init.css";

import React, { useState } from "react";
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonicSlides,
  IonImg,
  IonPage,
  IonRow,
  IonText
} from "@ionic/react";

const Init: React.FC<{
  openLoginHandler: () => void;
  openRegisterHandler: () => void;
}> = ({ openLoginHandler, openRegisterHandler }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <IonPage style={{ height: "100%" }}>
      <IonContent className={"init bg-image"} fullscreen>
        <IonGrid className={"ion-text-center"}>
          {/* <IonRow
            style={{
              paddingTop: "10%",
              maxHeight: "100px",
              minHeight: "100px",
            }}
          >
            <IonText className={"init-title-text"}>Foodware</IonText>
          </IonRow> */}

          <IonRow class="paddingTop25">
            <IonCol>
              <Swiper
                onActiveIndexChange={(swiper) => {
                  setCurrentSlide(swiper.activeIndex);
                }}
                onSwiper={(swiper) => {
                  swiper.autoplay.running = true;
                }}
                modules={[
                  Autoplay,
                  Keyboard,
                  Pagination,
                  Scrollbar,
                  Zoom,
                  IonicSlides,
                ]}
                slidesPerView={1}
                autoplay={{ delay: 30000 }}
                keyboard={true}
                pagination={true}
                scrollbar={false}
                zoom={true}
                loop={false}
              >
                <SwiperSlide>
                  <IonImg
                    src="assets/images/foodware.png"
                    style={{ margin: "auto", width: "50%" }}
                  ></IonImg>
                </SwiperSlide>
                {/*
                <SwiperSlide>
                  <IonImg
                    src="assets/images/init_slide2.png"
                    style={{ margin: "auto" }}
                  ></IonImg>
                </SwiperSlide>
                <SwiperSlide>
                  <IonImg
                    src="assets/images/init_slide3.png"
                    style={{ margin: "auto" }}
                  ></IonImg>
                </SwiperSlide> */}
              </Swiper>
            </IonCol>
          </IonRow>
          <IonRow
            className={"ion-text-center"}
            style={{
              minHeight: "39px",
              maxHeight: "39px",
              paddingTop: "0.5em",
            }}
          >
            <IonText
              className={"init-text ion-text-center"}
              style={{ paddingBottom: "0.5em" }}
            >
              Registramos tu Calidad
            </IonText>
          </IonRow>
          <IonRow style={{ paddingTop: "10%" }}>
            <IonCol>
              <IonButton
                className="login-button"
                expand="block"
                onClick={openLoginHandler}
              >
                Comenzar
              </IonButton>
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol>
              <IonButton
                disabled
                className="register-button"
                expand="block"
                fill="outline"
                onClick={openRegisterHandler}
              >
                Crear una cuenta
              </IonButton>
              <p style={{ fontSize: "small" }}>
                Al ingresar aceptas nuestra{" "}
                <a href="#">Política de Privacidad</a>
              </p>
            </IonCol>
          </IonRow> */}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Init;
