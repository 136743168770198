import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import notificationReducer from "./features/notifications/notifications";
import userReducer from "./features/user/user";
import { FWBackendApi } from "./services/FWBackendApi";
import { FWProfileApi } from "./services/FWProfileApi";

// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["loggedIn", "userAccessToken", "userRefreshToken", "userInfo"],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  notifications: notificationReducer,
  [FWProfileApi.reducerPath]: FWProfileApi.reducer,
  [FWBackendApi.reducerPath]: FWBackendApi.reducer,
});

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

// * Careful with typing: https://github.com/reduxjs/redux-thunk/issues/333
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(thunk)
      .concat(FWProfileApi.middleware)
      .concat(FWBackendApi.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState & PersistPartial>;
export type AppDispatch = typeof store.dispatch;
