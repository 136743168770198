/* eslint-disable @typescript-eslint/no-unused-vars */
import "./RegisterRecordCollectProducts.css";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  anyToFWProduct,
  FWAttributeFieldTypeEnum,
  FWObservableProductAttribute,
  FWProduct,
  FWReceptionBatchValue,
  FWReceptionIdentifier,
  FWReceptionObservableValue,
  FWRegisterCriteria,
  FWRegisterCriteriaChoice,
  FWRegisterCriteriaCorrectiveAction,
  FWRegisterCriteriaValue,
  FWRegisterRecordEntry,
  FWRegisterRecordTargetEnum
} from "foodware-lib";
import { addCircleOutline, arrowBack, close, saveOutline } from "ionicons/icons";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonProgressBar,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast
} from "@ionic/react";

import { FWBackendApi } from "../../services/FWBackendApi";
import { ButtonRef, RegisterRecordCollectProps } from "../types";

const isDefined = (val: unknown) =>
  val !== undefined && val !== null && val !== "";

const defaultCloseHandler = () => {
  console.log("Calling default close handler");
};

const RegisterRecordCollectProducts: React.FC<RegisterRecordCollectProps> = ({
  readOnly = false,
  closeHandler = defaultCloseHandler,
  registerRecord,
  registerFormat,
  refetch = () => Promise.resolve(null),
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [closeRegisterRecord] = FWBackendApi.useCloseRegisterRecordMutation();

  const [cancelRegisterRecord] = FWBackendApi.useCancelRegisterRecordMutation();

  const [updateRegisterRecordPreamble, { isLoading: isLoadingUpdatePreamble }] =
    FWBackendApi.useUpdateRegisterRecordPreambleMutation();

  const [collectRegisterRecordEntry, { isLoading: isLoadingCollectEntry }] =
    FWBackendApi.useCollectRegisterRecordEntryMutation();

  const [deleteRegisterRecordEntry, { isLoading: isLoadingDeleteEntry }] =
    FWBackendApi.useDeleteRegisterRecordEntryMutation();

  const [isPageLoading, setIsPageLoading] = useState(false);

  const saveByCurrentStep = () => {
    console.log(`Saving at current step ${currentStep}`);
    console.log(currentRegisterRecordEntryId);

    if (currentStep === 0) {
      saveRecordPreamble()
        .then((_) => refetch())
        .catch((error: unknown) => console.error(error));
    } else {
      const valuesReception = getValuesReception();
      console.log("Saving values reception");
      console.log(valuesReception);

      if (selectedProduct && registerRecord) {
        collectRegisterRecordEntry({
          ...valuesReception,
          productId: selectedProduct.id,
          registerRecordId: registerRecord.id,
          registerRecordEntryId: currentRegisterRecordEntryId,
        });
      }
    }
  };

  /****** PRODUCT_RECEPTION REGISTER FORMATS ******/
  const [selectedProduct, setSelectedProduct] = useState<FWProduct>();

  /****** CRITERIA MANAGEMENT ******/
  const criteria = (registerFormat?.criteria || []).sort(
    (a: FWRegisterCriteria, b: FWRegisterCriteria) => a.order - b.order
  );

  const [criteriaState, setCriteriaState] = useState<Record<string, boolean>>(
    {}
  );
  const setCriteriaStateAt = (criteriaId: string, value: boolean) => {
    const newCriteriaState: Record<string, boolean> = { ...criteriaState };
    newCriteriaState[criteriaId] = value;
    setCriteriaState(newCriteriaState);
  };

  //TODO validator must be determined according to field_type.
  type FormPreambleSchema = {
    validator: { name: string; value: unknown };
  };
  const formPreambleSchemaEntries: FormPreambleSchema[] = (
    registerFormat?.receptionPreambleIdentifiers || []
  ).map((ri: FWReceptionIdentifier) => {
    return {
      validator: {
        name: `preamble_${ri.id}`,
        value: yup.string().required("Debe indicar opción."),
      },
    };
  });

  type FormCriteriaSchema = {
    validator: { name: string; value: unknown };
    correctiveValidator: { name: string; value: unknown };
    correctiveValidatorComments: { name: string; value: unknown };
    criteria: FWRegisterCriteria;
  };

  const formSchemaEntries: FormCriteriaSchema[] = criteria.map(
    (c: FWRegisterCriteria) => {
      return {
        criteria: c,
        validator: {
          name: `criteria_${c.id}`,
          value: yup.string().required("Debe indicar opción."),
        },
        correctiveValidator: {
          name: `criteria_${c.id}_corrective`,
          value: yup.string().when(`criteria_${c.id}`, {
            is: false,
            then: (schema) =>
              schema.required("Debe indicar acción correctiva."),
          }),
        },
        correctiveValidatorComments: {
          name: `criteria_${c.id}_comments`,
          value: yup.string().when(`criteria_${c.id}`, {
            is: false,
            then: (schema) =>
              schema.required(
                "Debe indicar comentarios como acción correctiva."
              ),
          }),
        },
      };
    }
  );

  // write a yup schema where at least one field must be set
  const atLeastOneFieldSchema = yup
    .object()
    .test(
      "at-least-one-field",
      "Debe completar al menos un campo",
      (v, context) => {
        console.log("yup context");
        console.log(context);
        const fieldKeys = (registerFormat?.receptionBatchIdentifiers || []).map(
          (ri: FWReceptionIdentifier) => `batch_${ri.id}`
        );

        const filteredEntries = Object.fromEntries(
          Object.entries(context.parent).filter(([key, _]) =>
            fieldKeys.includes(key)
          )
        );

        console.log("entries:");
        console.log(filteredEntries);

        return fieldKeys.some((key) => !!filteredEntries[key]);
      }
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formSchemaObject: Record<string, any> = {};
  for (const entry of formSchemaEntries) {
    formSchemaObject[entry.validator.name] = entry.validator.value;

    // Make comments mandatory for non-compliance with no predefined
    // corrective actions
    if (entry.criteria.correctiveActions.length == 0) {
      formSchemaObject[entry.correctiveValidatorComments.name] =
        entry.correctiveValidatorComments.value;
    } else {
      formSchemaObject[entry.correctiveValidator.name] =
        entry.correctiveValidator.value;
    }
  }
  for (const entry of formPreambleSchemaEntries) {
    formSchemaObject[entry.validator.name] = entry.validator.value;
  }
  formSchemaObject["batch"] = atLeastOneFieldSchema;

  console.log(formSchemaObject);

  console.log("Register record");
  console.log(registerRecord);

  const formSchema = yup.object({ ...formSchemaObject });
  const initialValues = {
    registerRecordId: registerRecord?.id,
    comments: registerRecord?.comments,
    // registerRecordEntryId: undefined,
  };

  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  const radioButtonRefs: Record<string, Record<string, ButtonRef>> = {};
  if (criteria) {
    for (const c of criteria) {
      const criteriaId = `criteria_${c.id}`;
      const criteriaButtonRefs: Record<string, ButtonRef> = {};
      for (const choice of c.valueChoices) {
        criteriaButtonRefs[choice.id] = {
          button_ref: useRef(null),
          is_success: choice.is_success,
          is_error: choice.is_error,
        };
      }
      radioButtonRefs[criteriaId] = criteriaButtonRefs;
    }
  }

  console.log("button refs");
  console.log(radioButtonRefs);

  //* ***************** PRODUCT RECEPTION ******************************************* *//
  const {
    data: productsDataRaw,
    isFetching,
    // isSuccess: registerFormatsIsSuccess,
    // isError: registerFormatsIsError,
    // refetch: productsDataRefetch,
  } = FWBackendApi.useGetAllProductsQuery(
    {},
    {
      skip:
        !registerFormat ||
        (registerFormat &&
          registerFormat.target !==
            FWRegisterRecordTargetEnum.PRODUCT_RECEPTION),
    }
  );

  useEffect(() => {
    setIsPageLoading(
      isLoadingUpdatePreamble ||
        isLoadingCollectEntry ||
        isLoadingDeleteEntry ||
        isFetching
    );
  }, [
    isLoadingUpdatePreamble,
    isLoadingCollectEntry,
    isLoadingDeleteEntry,
    isFetching,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getIdentifierAttributeValue = (a: any) => {
    const value = [
      a.date_value,
      a.datetime_value,
      a.text_value,
      a.number_value,
      a.boolean_value,
    ].find((value) => value !== null && value !== undefined && value !== "");
    return value;
  };

  // * Initial preamble values from register record
  const initialPreambleValues: Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  > = {};

  for (const receptionPreambleValue of registerRecord?.receptionPreambleAttributeValues ||
    []) {
    // Get the first non-null value of a list of values
    const value = [
      receptionPreambleValue.date_value,
      receptionPreambleValue.datetime_value,
      receptionPreambleValue.text_value,
      receptionPreambleValue.number_value,
      receptionPreambleValue.boolean_value,
    ].find((value) => value !== null && value !== undefined);

    initialPreambleValues[
      `preamble_${receptionPreambleValue.reception_preamble_attribute.id}`
    ] = value;
  }

  console.log("initialPreambleValues");
  console.log(initialPreambleValues);

  // * Manage preamble identifiers data state
  const [preambleState, setPreambleState] = useState<
    Record<string, string | undefined | null>
  >(initialPreambleValues);

  const setPreambleStateAt = (
    preambleIdentifierId: string,
    value: string | null | undefined
  ) => {
    const newPreambleState = { ...preambleState };
    newPreambleState[preambleIdentifierId] = value;
    setPreambleState(newPreambleState);
  };

  //* ***************** INITIALIZE BATCH DATETIME VALUES **************************** *//
  //* Max and min dates for DATE fields
  //* Compute date at 31 december of today plus ten years, as iso string
  const computedMaxDate = new Date();
  computedMaxDate.setFullYear(computedMaxDate.getFullYear() + 10);
  computedMaxDate.setMonth(11); // December is 11
  computedMaxDate.setDate(31);
  const maxDateISOString = computedMaxDate.toISOString();

  // * Compute date as 1st of january 2020
  const computedMinDate = new Date(2020, 0, 1);
  const minDateISOString = computedMinDate.toISOString();

  // * Initial batch date input fields
  const initialBatchValues: Record<string, string | undefined | null> = {};
  const initialBatchState: Record<string, string | undefined | null> = {};
  // const initialBatchDatetimes: Record<
  //   string,
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   any
  // > = {};
  for (const receptionBatchIdentifier of registerRecord?.registerFormat
    ?.receptionBatchIdentifiers || []) {
    if (
      [
        FWAttributeFieldTypeEnum.DATETIME,
        FWAttributeFieldTypeEnum.DATE,
      ].includes(receptionBatchIdentifier.field_type)
    ) {
      const datetimeString = receptionBatchIdentifier?.isOptional
        ? null
        : new Date().toISOString();
      initialBatchState[receptionBatchIdentifier.id] = datetimeString;
      initialBatchValues[`batch_${receptionBatchIdentifier.id}`] =
        datetimeString;
    }
  }

  // // * Initialize all optional fields as enabled
  // const initialEnabledBatchState: Record<string, boolean> = {};
  // for (const receptionBatchIdentifier of registerRecord?.registerFormat
  //   ?.receptionBatchIdentifiers || []) {
  //   initialEnabledBatchState[receptionBatchIdentifier.id] = true;
  // }

  // // * Manage optional batch identifiers data state
  // const [batchEnabledState, setBatchEnabledState] = useState<
  //   Record<string, boolean>
  // >(initialEnabledBatchState);
  // const setBatchEnabledAt = (batchIdentifierId: string, value: boolean) => {
  //   const newBatchEnabledState = { ...batchEnabledState };
  //   newBatchEnabledState[batchIdentifierId] = value;
  //   setBatchEnabledState(newBatchEnabledState);
  // };

  const [batchState, setBatchState] =
    useState<Record<string, string | undefined | null>>(initialBatchState);
  const setBatchStateAt = (
    batchIdentifierId: string,
    value: string | null | undefined
  ) => {
    const newBatchState = { ...batchState };
    newBatchState[batchIdentifierId] = value;
    setBatchState(newBatchState);
  };

  // * State for reception preamble identifiers
  const {
    control,
    setValue: setValueReception,
    getValues: getValuesReception,
    register: registerReception,
    reset: resetReception,
    trigger: triggerReception,
    formState: { errors },
  } = useForm<Record<string, string | undefined | null>>({
    defaultValues: {
      ...initialPreambleValues,
      ...initialBatchValues,
      ...initialValues,
    },
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });

  // Utilitary validation functions
  const triggerPreamble = async () =>
    await triggerReception(
      (registerFormat?.receptionPreambleIdentifiers || []).map(
        (ri: FWReceptionIdentifier) => `preamble_${ri.id}`
      )
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isKindaDefined = (a: any) => {
    return a !== null && a !== undefined && a !== "";
  };

  const validateRegisterRecordEntry: (
    e: FWRegisterRecordEntry
  ) => Promise<{ registerRecordEntryId: string; isValid: boolean }> = async (
    e: FWRegisterRecordEntry
  ) => {
    // Returns true if all criteria are valid, or false otherwise.
    console.log("Validating entry:");
    console.log(e);
    console.log("Register Record");
    console.log(registerRecord);

    // * Validate batch identifiers values: at least one must be defined.
    const expectedBatchIdentifiers = Object.fromEntries(
      (registerRecord?.registerFormat.receptionBatchIdentifiers || []).map(
        (ri) => [ri.id, ri.name]
      )
    );
    console.log("expectedBatchIdentifiers");
    console.log(expectedBatchIdentifiers);

    const batchValuesValidity = (e?.registerBatchValues || []).map(
      (ri) =>
        ri.reception_batch_attribute.id in expectedBatchIdentifiers &&
        (isKindaDefined(ri.date_value) ||
          isKindaDefined(ri.datetime_value) ||
          isKindaDefined(ri.text_value) ||
          isKindaDefined(ri.number_value) ||
          isKindaDefined(ri.boolean_value))
    );
    const areBatchValuesValid =
      batchValuesValidity.some((v) => v === true) &&
      batchValuesValidity.length ===
        Object.keys(expectedBatchIdentifiers).length;

    console.log("areBatchValuesValid");
    console.log(areBatchValuesValid);

    // * Validate criteria values: all must be defined.
    const expectedCriteria = Object.fromEntries(
      (registerRecord?.registerFormat.criteria || []).map((c) => [c.id, c.name])
    );
    const criteriaValuesValidity = (e?.registerCriteriaValues || []).map(
      (cv) =>
        cv.register_criteria.id in expectedCriteria && isKindaDefined(cv.value)
    );
    const areCriteriaValuesValid =
      criteriaValuesValidity.every((v) => v === true) &&
      criteriaValuesValidity.length === Object.keys(expectedCriteria).length;

    console.log("areCriteriaValuesValid");
    console.log(areCriteriaValuesValid);

    // * Validate observable values: all must be defined.
    const expectedObservableValues = Object.fromEntries(
      (registerRecord?.registerFormat.observableProductAttributes || []).map(
        (opa) => [opa.id, opa.name]
      )
    );
    const observableValuesValidity = (e?.registerObservableValues || []).map(
      (ov) =>
        ov.reception_observable_attribute.id in expectedObservableValues &&
        (isKindaDefined(ov.date_value) ||
          isKindaDefined(ov.datetime_value) ||
          isKindaDefined(ov.text_value) ||
          isKindaDefined(ov.number_value) ||
          isKindaDefined(ov.boolean_value))
    );

    const areObservableValuesValid =
      observableValuesValidity.every((v) => v === true) &&
      observableValuesValidity.length ===
        Object.keys(expectedObservableValues).length;

    console.log("areObservableValuesValid");
    console.log(areObservableValuesValid);

    const isEntryValid =
      areBatchValuesValid && areCriteriaValuesValid && areObservableValuesValid;

    console.log("isEntryValid");
    console.log(isEntryValid);

    return { registerRecordEntryId: e.id, isValid: isEntryValid };
  };

  const validateRecordEntries: () => Promise<boolean> = async () => {
    // Clear previous validation errors.
    setEntriesValidationErrors({});

    // Construct a list of promises calling validateRegisterRecordEntry for each entry
    const entriesValidationPromises = (registerRecord?.entries || []).map((e) =>
      validateRegisterRecordEntry(e)
    );

    // Wait for all promises to resolve
    const entriesValidationResults = await Promise.all(
      entriesValidationPromises
    );

    console.log("entriesValidationResults");
    console.log(entriesValidationResults);

    // Check all results are true or not
    const areEntriesValid = entriesValidationResults.every(
      (r: { isValid: boolean }) => r.isValid === true
    );
    console.log(`areEntriesValid: ${areEntriesValid}`);

    // * Update entry cards with error labels as required.
    const update: Record<string, boolean> = {};
    if (areEntriesValid === false) {
      for (const entryValidationResult of entriesValidationResults) {
        if (entryValidationResult.isValid === false) {
          // * Find entry card and add error label
          update[entryValidationResult.registerRecordEntryId] = true;
        }
      }
    }
    setEntriesValidationErrors(update);

    return areEntriesValid;
  };

  const [observacionesGenerales, setObservacionesGenerales] = useState<
    string | null
  >();
  const [productoFormatoCodigo, setProductoFormatoCodigo] = useState<string>();

  const productToStr = (p: FWProduct | undefined) => {
    return p?.name || "";
  };

  console.log("products data raw:");
  console.log(productsDataRaw);

  const productsData = (productsDataRaw || []).map(anyToFWProduct);

  console.log("products data:");
  console.log(productsData);

  console.log(errors);

  const [searchText, setSearchText] = useState("");
  const [searchProductResults, setSearchProductResults] = useState([]);
  const searchProduct = () => {
    // Filter by text similarity in productsData array.
    if (searchText !== "") {
      const filteredProducts =
        (searchText &&
          (productsData || []).filter((p: FWProduct) => {
            const productStr = productToStr(p);
            return productStr.toLowerCase().includes(searchText.toLowerCase());
          })) ||
        [];
      setSearchProductResults(filteredProducts);
    } else {
      setSearchProductResults([]);
    }
  };

  useEffect(() => {
    searchProduct();
  }, [searchText]);

  const [step0Segment, setStep0Segment] = useState("oc");
  const [currentRegisterRecordEntryId, setCurrentRegisterRecordEntryId] =
    useState<string | undefined>();

  const restoreFormDataFromEntry = (entry: FWRegisterRecordEntry) => {
    //* Reset all fields
    resetReception({
      ...initialPreambleValues,
      ...initialBatchValues,
      ...initialValues,
    });

    // * Set register record entry id
    setValueReception("registerRecordEntryId", entry.id);
    setCurrentRegisterRecordEntryId(entry.id);

    //* Selected product
    if (entry?.product) {
      setSelectedProduct(entry.product);

      //* Batch values
      const newBatchValues: Record<string, string> = {};
      for (const batchValue of entry.registerBatchValues) {
        const ri = batchValue.reception_batch_attribute;
        const batchValueValue = getIdentifierAttributeValue(batchValue);
        setValueReception(`batch_${ri.id}`, batchValueValue);
        newBatchValues[ri.id] = batchValueValue;
      }
      setBatchState({ ...batchState, ...newBatchValues });

      //* Observable attributes
      for (const observable of entry.registerObservableValues) {
        const observableId = observable?.reception_observable_attribute?.id;
        const value = getIdentifierAttributeValue(observable);
        setValueReception(`observable_${observableId}`, value);
      }

      //* Criteria
      const newCriteriaValues: Record<string, boolean> = {};
      for (const criteriaValue of entry.registerCriteriaValues) {
        const criteriaId = criteriaValue?.register_criteria?.id;
        // const value = criteriaValue?.value;
        setValueReception(`criteria_${criteriaId}`, criteriaValue?.id);
        newCriteriaValues[criteriaId] = criteriaValue?.is_error;

        if (criteriaValue?.correctiveAction?.id) {
          setValueReception(
            `criteria_${criteriaId}_corrective`,
            criteriaValue.correctiveAction.id
          );
        }

        if (criteriaValue?.comments) {
          setValueReception(
            `criteria_${criteriaId}_comments`,
            criteriaValue.comments
          );
        }
      }
      setCriteriaState({ ...criteriaState, ...newCriteriaValues });
    }
  };

  const createNewEntryFromSelectedProduct = () => {
    if (selectedProduct && registerRecord) {
      resetReception({
        ...initialPreambleValues,
        ...initialBatchValues,
        ...initialValues,
      });

      setBatchState(initialBatchState);

      const valuesReception = getValuesReception();
      console.log("Values reception");
      console.log(valuesReception);

      collectRegisterRecordEntry({
        ...valuesReception,
        productId: selectedProduct.id,
        registerRecordId: registerRecord.id,
        registerRecordEntryId: undefined,
      })
        .unwrap()
        .then((returnedEntryId: string) => {
          console.log("Returned entry id");
          console.log(returnedEntryId);

          setCurrentRegisterRecordEntryId(returnedEntryId);
          setValueReception("registerRecordEntryId", returnedEntryId);
          setCurrentStep(currentStep + 1);
        });
    }
  };

  const setupToCreateNewEntry = () => {
    if (registerRecord) {
      saveRecordPreamble().then(() => {
        resetReception({
          ...initialPreambleValues,
          ...initialBatchValues,
          ...initialValues,
          ...preambleState,
        });

        setPreambleState(preambleState);
        setBatchState(initialBatchState);
        setCurrentRegisterRecordEntryId(undefined);
        setCurrentStep(1);
      });
    }
  };

  const saveRecordPreamble = () => {
    //const isPreambleValid = await triggerPreamble();
    if (registerRecord) {
      return updateRegisterRecordPreamble({
        registerRecordId: registerRecord.id,
        preambleData: {
          ...preambleState,
          comments: observacionesGenerales,
        },
      }).unwrap();
    } else {
      return Promise.resolve(null);
    }
  };

  const [entriesValidationErrors, setEntriesValidationErrors] = useState<
    Record<string, boolean>
  >({});

  console.log("Preamble State");
  console.log(preambleState);

  console.log("Batch State");
  console.log(batchState);

  // console.log("Batch Enabled State");
  // console.log(batchEnabledState);

  console.log("Criteria State");
  console.log(criteriaState);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {currentStep === 0 && (
              <IonButton onClick={closeHandler}>
                <IonIcon slot="icon-only" icon={close}></IonIcon>
              </IonButton>
            )}
            {currentStep === 1 && (
              <IonButton
                onClick={() => {
                  refetch().then((_) => setCurrentStep(0));
                }}
              >
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButton>
            )}
            {currentStep > 1 && (
              <IonButton onClick={() => setCurrentStep(currentStep - 1)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
          <IonButtons slot="end">
            {!readOnly && (
              <IonButton onClick={() => setupToCreateNewEntry()}>
                <IonIcon slot="icon-only" icon={addCircleOutline}></IonIcon>
              </IonButton>
            )}
            {!readOnly && currentStep >= 0 && (
              <IonButton onClick={() => saveByCurrentStep()}>
                <IonIcon slot="icon-only" icon={saveOutline}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
          <IonTitle style={{ fontSize: "smaller" }}>
            {registerRecord && `#${registerRecord.id} ${registerRecord.title}`}
          </IonTitle>
          {(isFetching || isLoadingUpdatePreamble) && (
            <IonProgressBar
              type="indeterminate"
              color="secondary"
            ></IonProgressBar>
          )}
        </IonToolbar>
      </IonHeader>

      {/* ***************************** STEP 0 ***************************** */}

      {registerRecord && registerFormat && currentStep === 0 && (
        <>
          <IonContent fullscreen>
            <IonLoading
              backdropDismiss={false}
              isOpen={isPageLoading}
            ></IonLoading>
            <IonSegment
              value={step0Segment}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onIonChange={(evt) => setStep0Segment(evt.detail.value!)}
            >
              <IonSegmentButton value="oc">
                <IonLabel>Datos OC</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="registers">
                <IonLabel>
                  Entradas ({(registerRecord?.entries || []).length})
                  {entriesValidationErrors &&
                    Object.keys(entriesValidationErrors).length > 0 && (
                      <IonBadge color="danger">
                        {Object.keys(entriesValidationErrors).length}
                      </IonBadge>
                    )}
                </IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {step0Segment === "oc" && (
              <>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Datos de Orden</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      {(registerFormat?.receptionPreambleIdentifiers || []).map(
                        (ri: FWReceptionIdentifier) => (
                          <>
                            <IonItem key={ri.id}>
                              <IonLabel
                                className="ion-text-wrap"
                                position="stacked"
                              >
                                {ri.name}
                              </IonLabel>
                              {ri.field_type ===
                                FWAttributeFieldTypeEnum.TEXT && (
                                <IonInput
                                  {...registerReception(`preamble_${ri.id}`)}
                                  type="text"
                                  placeholder={!readOnly ? "Ingrese" : ""}
                                  onIonChange={(evt) => {
                                    setPreambleStateAt(
                                      `preamble_${ri.id}`,
                                      evt.detail.value
                                    );
                                  }}
                                />
                              )}
                              {ri.field_type ===
                                FWAttributeFieldTypeEnum.NUMBER && (
                                <IonInput
                                  {...registerReception(`preamble_${ri.id}`)}
                                  type="text"
                                  onIonChange={(evt) => {
                                    setPreambleStateAt(
                                      `preamble_${ri.id}`,
                                      evt.detail.value
                                    );
                                  }}
                                />
                              )}
                            </IonItem>

                            <ErrorMessage
                              errors={errors}
                              name={`preamble_${ri.id}`}
                              render={({ message }) => (
                                <IonItem>
                                  <IonText style={{ color: "red" }}>
                                    {message}
                                  </IonText>
                                </IonItem>
                              )}
                            />
                          </>
                        )
                      )}
                      <IonItem key={"observacionesGenerales"}>
                        <IonLabel position="stacked">
                          Observaciones Generales
                        </IonLabel>
                        <IonTextarea
                          placeholder={!readOnly ? "Ingrese" : ""}
                          autoGrow
                          {...registerReception(`comments`)}
                          onIonChange={(evt) => {
                            setObservacionesGenerales(evt.detail.value);
                          }}
                        />
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </>
            )}

            {step0Segment === "registers" && (
              <div>
                <IonText className="ion-text-center">
                  <h3>
                    Entradas Ingresadas (
                    {(registerRecord?.entries || []).length})
                  </h3>
                </IonText>

                <IonList className="ion-no-padding">
                  {(registerRecord.entries || []).map(
                    (e: FWRegisterRecordEntry) => {
                      return (
                        <IonItem key={e.id}>
                          <IonCard
                            className="ion-no-padding"
                            style={{ width: "100%" }}
                          >
                            <IonCardHeader
                              className="ion-padding-horizontal ion-no-margin"
                              style={{ paddingBottom: 0 }}
                            >
                              <IonCardSubtitle
                                className={"ion-padding-horizontal"}
                              >
                                <IonText>#{e.id}</IonText>
                                <IonText
                                  className="ion-float-right"
                                  style={{ fontWeight: 900 }}
                                  color="primary"
                                >
                                  {e?.product?.internal_code}
                                </IonText>
                              </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                              <IonGrid>
                                {entriesValidationErrors &&
                                  entriesValidationErrors[e.id] === true && (
                                    <IonRow className="ion-text-center">
                                      <IonCol>
                                        <IonText
                                          style={{ fontWeight: 800 }}
                                          color="danger"
                                        >
                                          Faltan datos obligatorios
                                        </IonText>
                                      </IonCol>
                                    </IonRow>
                                  )}
                                <IonRow className="ion-text-center">
                                  <IonCol className="ion-no-padding ion-no-margin">
                                    <IonText
                                      style={{ fontWeight: 700 }}
                                      color="primary"
                                    >
                                      {productToStr(e?.product)}
                                    </IonText>
                                  </IonCol>
                                </IonRow>
                                {(e?.registerBatchValues || []).map(
                                  (bv: FWReceptionBatchValue, idx: number) => {
                                    const bvValue =
                                      getIdentifierAttributeValue(bv);
                                    return isDefined(bvValue) ? (
                                      <IonRow key={`e_${e.id}_${idx}`}>
                                        <IonCol size="8">
                                          {bv.reception_batch_attribute.name}:
                                        </IonCol>
                                        <IonCol size="4">{bvValue}</IonCol>
                                      </IonRow>
                                    ) : null;
                                  }
                                )}

                                {/* *** FOR READ-ONLY MODE SHOW ALL ENTRY VALUES *** */}
                                {(readOnly || true) && (
                                  <>
                                    {(e?.registerObservableValues || []).map(
                                      (
                                        oba: FWReceptionObservableValue,
                                        idx: number
                                      ) => {
                                        const obaValue =
                                          getIdentifierAttributeValue(oba);
                                        return isDefined(obaValue) ? (
                                          <IonRow key={`e_${e.id}_oba_${idx}`}>
                                            <IonCol size="8">
                                              {
                                                oba
                                                  .reception_observable_attribute
                                                  .name
                                              }
                                            </IonCol>
                                            <IonCol size="4">{obaValue}</IonCol>
                                          </IonRow>
                                        ) : null;
                                      }
                                    )}

                                    {(e?.registerCriteriaValues || []).map(
                                      (
                                        rcv: FWRegisterCriteriaValue,
                                        idx: number
                                      ) => {
                                        return isDefined(rcv.value) ? (
                                          <IonRow
                                            key={`e_${e.id}_criteria_${idx}`}
                                          >
                                            <IonCol size="8">
                                              {rcv.register_criteria.name}
                                            </IonCol>
                                            <IonCol size="4">
                                              {rcv.value}
                                            </IonCol>
                                          </IonRow>
                                        ) : null;
                                      }
                                    )}
                                  </>
                                )}
                              </IonGrid>
                            </IonCardContent>
                            {!readOnly && (
                              <IonButton
                                fill="clear"
                                onClick={() => {
                                  console.log(`Deleting entry ${e.id}`);

                                  presentAlert({
                                    header: "Confirma",
                                    subHeader: "¿Desea eliminar esta entrada?",
                                    message: "Esta operación es irreversible.",
                                    buttons: [
                                      {
                                        text: "Eliminar",
                                        role: "confirm",
                                        handler: () => {
                                          console.log(
                                            `Confirmed deleting entry ${e.id}`
                                          );
                                          deleteRegisterRecordEntry(e.id)
                                            .unwrap()
                                            .then(() => {
                                              refetch().then(() => {
                                                setCurrentRegisterRecordEntryId(
                                                  undefined
                                                );
                                                setSelectedProduct(undefined);
                                                presentToast({
                                                  duration: 3000,
                                                  message:
                                                    "Se eliminó correctamente la entrada",
                                                  position: "top",
                                                  buttons: [
                                                    {
                                                      text: "OK",
                                                      role: "cancel",
                                                    },
                                                  ],
                                                });
                                              });
                                            })
                                            .catch((error: unknown) =>
                                              console.error(error)
                                            );
                                        },
                                      },
                                      {
                                        text: "No eliminar",
                                        role: "cancel",
                                      },
                                    ],
                                  });
                                }}
                              >
                                Eliminar
                              </IonButton>
                            )}
                            {!readOnly && (
                              <IonButton
                                fill="clear"
                                className="ion-float-right"
                                onClick={() => {
                                  console.log(
                                    `Restoring entry ${e.id} to edit form`
                                  );

                                  //* Restore form data based on this entry
                                  restoreFormDataFromEntry(e);

                                  //* Set current step based on existing entry data:
                                  const entryProduct = e?.product;
                                  const countEntryBatchValues = (
                                    e?.registerBatchValues || []
                                  ).length;
                                  if (entryProduct) {
                                    if (countEntryBatchValues === 0) {
                                      setCurrentStep(2);
                                    } else if (countEntryBatchValues > 0) {
                                      setCurrentStep(3);
                                    }
                                    //* Set current segment to "Datos OC"
                                    setStep0Segment("oc");
                                  }
                                }}
                              >
                                Editar
                              </IonButton>
                            )}
                          </IonCard>
                        </IonItem>
                      );
                    }
                  )}
                </IonList>
              </div>
            )}
          </IonContent>
          {!readOnly && (
            <IonFooter>
              <IonButton
                type="submit"
                expand="block"
                onClick={async () => {
                  presentAlert({
                    header: "Confirma",
                    subHeader: "¿Desea guardar este registro?",
                    message:
                      "Si lo haces, ya no podrás ingresar más datos en este registro.",
                    buttons: [
                      {
                        text: "Finalizar Registro",
                        role: "confirm",
                        handler: async () => {
                          console.log("Confirm");
                          // * Check if whole record and its entries is valid.
                          const isPreambleValid = await triggerPreamble();
                          const areEntriesValid = await validateRecordEntries();
                          console.log("entriesValidationErrors");
                          console.log(entriesValidationErrors);
                          if (isPreambleValid && areEntriesValid) {
                            closeRegisterRecord(registerRecord.id)
                              .unwrap()
                              .then(() => {
                                closeHandler();
                                presentToast({
                                  duration: 3000,
                                  message:
                                    "Se registraron correctamente los datos de recepción",
                                  position: "top",
                                  buttons: [
                                    {
                                      text: "OK",
                                      role: "cancel",
                                    },
                                  ],
                                });
                              })
                              .catch((error: unknown) => console.error(error));
                          } else {
                            presentToast({
                              message:
                                "No se puede finalizar el registro porque hay datos faltantes. Por favor verifique y corrija.",
                              position: "top",
                              buttons: [
                                {
                                  text: "OK",
                                  role: "cancel",
                                },
                              ],
                            });
                          }
                        },
                      },
                      {
                        text: "Cancelar",
                        role: "cancel",
                      },
                    ],
                  });
                }}
              >
                Finalizar Registro
              </IonButton>

              <IonButton
                type="submit"
                color="danger"
                expand="block"
                onClick={() => {
                  presentAlert({
                    header: "Confirma",
                    subHeader: "¿Desea cancelar este registro?",
                    message:
                      "Si lo haces, no quedará guardado ningún dato que haya sido ingresado a este registro.",
                    buttons: [
                      {
                        text: "Cancelar Registro",
                        role: "confirm",
                        handler: () => {
                          console.log("Confirm");
                          cancelRegisterRecord(registerRecord.id)
                            .unwrap()
                            .then(() => {
                              closeHandler();
                            })
                            .catch((error: unknown) => console.error(error));
                        },
                      },
                      {
                        text: "No cancelar",
                        role: "cancel",
                      },
                    ],
                  });
                }}
              >
                Cancelar Registro
              </IonButton>
            </IonFooter>
          )}
        </>
      )}

      {/* ***************************** STEP 1 ***************************** */}
      {registerRecord && registerFormat && currentStep === 1 && (
        <IonContent>
          <IonLoading
            backdropDismiss={false}
            isOpen={isPageLoading}
          ></IonLoading>
          <IonCard>
            {currentRegisterRecordEntryId && (
              <IonCardHeader>
                <h4>
                  Editando entrada existente #{currentRegisterRecordEntryId}
                </h4>
              </IonCardHeader>
            )}

            <IonCardContent>
              <h2>Datos generales</h2>
              <hr />
              {(registerFormat?.receptionPreambleIdentifiers || []).map(
                (ri: FWReceptionIdentifier) => {
                  const preambleValue = preambleState[`preamble_${ri.id}`];
                  return isDefined(preambleValue) ? (
                    <h2 key={ri.id}>
                      {ri.name}: {preambleValue}
                    </h2>
                  ) : null;
                }
              )}
            </IonCardContent>
          </IonCard>

          <IonCard>
            <IonCardHeader>
              {!selectedProduct && (
                <IonCardSubtitle>Seleccione producto</IonCardSubtitle>
              )}
              {selectedProduct && (
                <IonCardSubtitle>Producto seleccionado</IonCardSubtitle>
              )}
            </IonCardHeader>
            <IonCardContent>
              {selectedProduct && (
                <IonText>{productToStr(selectedProduct)}</IonText>
              )}
              {!selectedProduct && (
                <IonText>Aún no has seleccionado un producto</IonText>
              )}
              <IonSearchbar
                type="text"
                animated
                placeholder="Buscar producto"
                style={{ width: "100%" }}
                value={searchText}
                onIonClear={() => setSearchText("")}
                onIonChange={(evt) => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  setSearchText(evt.detail.value!);
                }}
              ></IonSearchbar>
              <IonList>
                {searchProductResults &&
                  (searchProductResults || []).map((p: FWProduct) => (
                    <IonItem
                      key={`search_result_${p.id}`}
                      detail
                      button
                      onClick={() => {
                        setSelectedProduct(p);
                        setSearchText("");
                      }}
                    >
                      <IonLabel className="ion-text-wrap">
                        {productToStr(p)}
                      </IonLabel>
                    </IonItem>
                  ))}
              </IonList>
            </IonCardContent>
          </IonCard>

          {currentRegisterRecordEntryId && (
            <IonButton
              expand="block"
              fill="outline"
              disabled={!selectedProduct}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
            >
              Continuar editando
            </IonButton>
          )}
          {!currentRegisterRecordEntryId && (
            <IonButton
              expand="block"
              fill="outline"
              disabled={!selectedProduct}
              onClick={() => createNewEntryFromSelectedProduct()}
            >
              Crear nueva entrada
            </IonButton>
          )}
        </IonContent>
      )}

      {/* ***************************** STEP 2 ***************************** */}
      {registerRecord &&
        registerFormat &&
        currentStep === 2 &&
        (selectedProduct || productoFormatoCodigo) && (
          <IonContent>
            <IonLoading
              backdropDismiss={false}
              isOpen={isPageLoading}
            ></IonLoading>
            <IonCard>
              {currentRegisterRecordEntryId && (
                <IonCardHeader>
                  <h4>
                    Editando entrada existente #{currentRegisterRecordEntryId}
                  </h4>
                </IonCardHeader>
              )}
              <IonCardContent>
                <h2>Datos generales</h2>
                <hr />
                {(registerFormat?.receptionPreambleIdentifiers || []).map(
                  (ri: FWReceptionIdentifier) => {
                    const preambleValue = preambleState[`preamble_${ri.id}`];

                    let year: number | null = null;
                    let month: number | null = null;
                    let day: number | null = null;
                    if (
                      preambleValue &&
                      FWAttributeFieldTypeEnum.DATE === ri.field_type
                    ) {
                      try {
                        const [the_year, the_month, the_day] =
                          preambleValue.split("-");
                        year = parseInt(the_year);
                        month = parseInt(the_month);
                        day = parseInt(the_day);
                      } catch (exc) {
                        console.log("Error parsing/splitting date");
                        console.error(exc);
                      }
                    }

                    return (
                      <>
                        {[
                          FWAttributeFieldTypeEnum.TEXT,
                          FWAttributeFieldTypeEnum.NUMBER,
                        ].includes(ri.field_type) &&
                          isDefined(preambleValue) && (
                            <h2 key={ri.id}>
                              {ri.name}: {preambleValue}
                            </h2>
                          )}
                        {[FWAttributeFieldTypeEnum.DATE].includes(
                          ri.field_type
                        ) &&
                          isDefined(preambleValue) && (
                            <h2 key={ri.id}>
                              {ri.name}:{" "}
                              {preambleValue &&
                                year &&
                                month &&
                                day &&
                                format(
                                  new Date(year, month - 1, day),
                                  "dd/MM/yyyy",
                                  { locale: es }
                                )}
                            </h2>
                          )}
                        {/* {[FWAttributeFieldTypeEnum.DATETIME].includes(
                          ri.field_type
                        ) &&
                          isDefined(preambleValue) && (
                            <h2 key={ri.id}>
                              {ri.name}:{" "}
                              {preambleValue &&
                                dateFormat(preambleValue, "dd/mm/yyyy HH:MM")}
                            </h2>
                          )} */}
                      </>
                    );
                  }
                )}
                <hr />
                <h2>Producto</h2>
                {productToStr(selectedProduct)}
                <hr />
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Identificación de Lote</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {(registerFormat?.receptionBatchIdentifiers || []).map(
                    (ri: FWReceptionIdentifier) => {
                      return (
                        <>
                          {ri.field_type === FWAttributeFieldTypeEnum.TEXT && (
                            <IonItem key={ri.id}>
                              <IonLabel
                                className="ion-text-wrap"
                                position="stacked"
                              >
                                {ri.name}
                              </IonLabel>
                              <IonInput
                                {...registerReception(`batch_${ri.id}`)}
                                type="text"
                                placeholder={!readOnly ? "Ingrese" : ""}
                                onIonChange={(evt) => {
                                  setBatchStateAt(ri.id, evt.detail.value);
                                }}
                              />
                            </IonItem>
                          )}
                          {ri.field_type ===
                            FWAttributeFieldTypeEnum.NUMBER && (
                            <IonItem key={ri.id}>
                              <IonLabel
                                className="ion-text-wrap"
                                position="stacked"
                              >
                                {ri.name}
                              </IonLabel>
                              <IonInput
                                {...registerReception(`batch_${ri.id}`)}
                                type="text"
                                onIonChange={(evt) => {
                                  setBatchStateAt(ri.id, evt.detail.value);
                                }}
                              />
                            </IonItem>
                          )}
                          {ri.field_type === FWAttributeFieldTypeEnum.DATE && (
                            <>
                              <IonItem key={ri.id} lines="none">
                                <IonLabel
                                  className="ion-text-wrap"
                                  style={{ width: "100%" }}
                                >
                                  {ri.name}
                                </IonLabel>

                                <Controller
                                  control={control}
                                  name={`batch_${ri.id}`}
                                  render={({ field }) => {
                                    const batchId = `batch_${ri.id}`;
                                    const currentValue =
                                      getValuesReception(batchId);
                                    return (
                                      <>
                                        <IonDatetimeButton
                                          datetime={`datetime_${ri.id}`}
                                        >
                                          {!isDefined(currentValue) && (
                                            <IonText slot="date-target">
                                              Elegir...
                                            </IonText>
                                          )}
                                        </IonDatetimeButton>
                                        <IonModal keepContentsMounted={true}>
                                          <IonDatetime
                                            id={`datetime_${ri.id}`}
                                            min={minDateISOString}
                                            max={maxDateISOString}
                                            showDefaultButtons
                                            clearText="Borrar"
                                            doneText="Confirmar"
                                            cancelText="Cerrar"
                                            showClearButton={ri.isOptional}
                                            presentation="date"
                                            preferWheel={false}
                                            multiple={false}
                                            {...field}
                                            onIonChange={(evt) => {
                                              //* IonDatetime doesn't like null as input,
                                              //* hence we update it to the empty string.
                                              if (evt?.detail?.value) {
                                                const newDateString = evt
                                                  ?.detail?.value as string;

                                                setBatchStateAt(
                                                  ri.id,
                                                  newDateString
                                                );
                                                setValueReception(
                                                  batchId,
                                                  newDateString
                                                );
                                              } else {
                                                setBatchStateAt(ri.id, "");
                                                setValueReception(batchId, "");
                                              }
                                            }}
                                          >
                                            <span slot="title">
                                              Seleccione fecha
                                            </span>
                                          </IonDatetime>
                                        </IonModal>
                                      </>
                                    );
                                  }}
                                />
                              </IonItem>
                            </>
                          )}
                        </>
                      );
                    }
                  )}
                </IonList>
              </IonCardContent>
            </IonCard>

            <IonButton
              expand="block"
              fill="outline"
              disabled={!selectedProduct && !productoFormatoCodigo}
              onClick={async () => {
                await saveByCurrentStep();
                setCurrentStep(currentStep + 1);
              }}
            >
              Continuar
            </IonButton>
          </IonContent>
        )}

      {/* ***************************** STEP 3 ***************************** */}

      {registerRecord &&
        registerFormat &&
        currentStep === 3 &&
        (selectedProduct || productoFormatoCodigo) && (
          <>
            <IonContent>
              <IonLoading
                backdropDismiss={false}
                isOpen={isPageLoading}
              ></IonLoading>
              <IonCard>
                {currentRegisterRecordEntryId && (
                  <IonCardHeader>
                    <h4>
                      Editando entrada existente #{currentRegisterRecordEntryId}
                    </h4>
                  </IonCardHeader>
                )}
                <IonCardContent>
                  <h2>Datos generales</h2>
                  <hr />
                  {(registerFormat?.receptionPreambleIdentifiers || []).map(
                    (ri: FWReceptionIdentifier) => {
                      const preambleValue = preambleState[`preamble_${ri.id}`];
                      return isDefined(preambleValue) ? (
                        <h2 key={ri.id}>
                          {ri.name}: {preambleValue}
                        </h2>
                      ) : null;
                    }
                  )}
                  <hr />
                  <h2>Producto</h2>
                  {productToStr(selectedProduct)}
                  <hr />

                  <hr />
                  {(registerFormat?.receptionBatchIdentifiers || []).map(
                    (ri: FWReceptionIdentifier) => {
                      const batchValue = batchState[ri.id];

                      let year: number | null = null;
                      let month: number | null = null;
                      let day: number | null = null;
                      if (
                        batchValue &&
                        FWAttributeFieldTypeEnum.DATE === ri.field_type
                      ) {
                        try {
                          const [the_year, the_month, the_day] =
                            batchValue.split("-");
                          year = parseInt(the_year);
                          month = parseInt(the_month);
                          day = parseInt(the_day);
                        } catch (exc) {
                          console.log("Error parsing/splitting date");
                          console.error(exc);
                        }
                      }

                      return (
                        <>
                          {[
                            FWAttributeFieldTypeEnum.TEXT,
                            FWAttributeFieldTypeEnum.NUMBER,
                          ].includes(ri.field_type) &&
                            isDefined(batchValue) && (
                              <h2 key={ri.id}>
                                {ri.name}: {batchValue}
                              </h2>
                            )}
                          {[FWAttributeFieldTypeEnum.DATE].includes(
                            ri.field_type
                          ) &&
                            isDefined(batchValue) && (
                              <h2 key={ri.id}>
                                {ri.name}:{" "}
                                {batchValue &&
                                  year &&
                                  month &&
                                  day &&
                                  format(
                                    new Date(year, month - 1, day),
                                    "dd/MM/yyyy",
                                    { locale: es }
                                  )}
                              </h2>
                            )}
                          {/* {[FWAttributeFieldTypeEnum.DATETIME].includes(
                            ri.field_type
                          ) &&
                            isDefined(batchValue) && (
                              <h2 key={ri.id}>
                                {ri.name}:{" "}
                                {batchValue &&
                                  format(
                                    new Date(batchValue),
                                    "dd/MM/yyy HH:mm",
                                    {
                                      locale: es,
                                    }
                                  )}
                              </h2>
                            )} */}
                        </>
                      );
                    }
                  )}
                </IonCardContent>
              </IonCard>

              {/* Render observable attributes */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Observaciones</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {(registerFormat.observableProductAttributes || []).map(
                    (opa: FWObservableProductAttribute) => {
                      return (
                        <IonItem key={opa.id}>
                          <IonLabel
                            className="ion-text-wrap"
                            position="stacked"
                          >
                            {opa.name}
                          </IonLabel>
                          {opa.field_type === FWAttributeFieldTypeEnum.TEXT && (
                            <IonInput
                              {...registerReception(`observable_${opa.id}`)}
                              type="text"
                              placeholder={
                                !readOnly ? "Ingrese observación" : ""
                              }
                            />
                          )}
                          {opa.field_type ===
                            FWAttributeFieldTypeEnum.NUMBER && (
                            <IonInput
                              {...registerReception(`observable_${opa.id}`)}
                              type="text"
                              placeholder={
                                !readOnly ? "Ingrese observación" : ""
                              }
                            />
                          )}
                        </IonItem>
                      );
                    }
                  )}
                </IonCardContent>
              </IonCard>

              {/* Render register criteria */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Criterios</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <form>
                    <IonGrid className={"ion-no-padding"}>
                      {criteria.map((c: FWRegisterCriteria) => {
                        return (
                          <IonRow key={c.id}>
                            <IonCol>
                              <IonCard className="register-criteria-card">
                                <IonCardHeader>
                                  <IonCardSubtitle>{c.name}</IonCardSubtitle>
                                </IonCardHeader>

                                <IonRadioGroup
                                  {...registerReception(`criteria_${c.id}`)}
                                  value={getValuesReception(`criteria_${c.id}`)}
                                  onIonChange={(evt) => {
                                    console.log(`Change: ${evt.detail.value}`);

                                    const chosenChoice = c.valueChoices.find(
                                      (choice) => choice.id === evt.detail.value
                                    );
                                    console.log("Chosen Choice");
                                    console.log(chosenChoice);
                                    if (chosenChoice) {
                                      setCriteriaStateAt(
                                        `${c.id}`,
                                        chosenChoice.is_error
                                      );
                                      setValueReception(
                                        `criteria_${c.id}`,
                                        chosenChoice.id
                                      );
                                    }
                                  }}
                                >
                                  {/* RENDER VALUE CHOICES RECEPTION*/}

                                  {(c.valueChoices || []).map(
                                    (choice: FWRegisterCriteriaChoice) => {
                                      return (
                                        <IonItem lines="none" key={choice.id}>
                                          <IonLabel
                                            onClick={() => {
                                              const button =
                                                radioButtonRefs[
                                                  `criteria_${c.id}`
                                                ][choice.id]["button_ref"]
                                                  ?.current;

                                              if (button) {
                                                button.click();
                                              }
                                            }}
                                          >
                                            {choice.value}
                                          </IonLabel>
                                          <IonRadio
                                            ref={
                                              radioButtonRefs[
                                                `criteria_${c.id}`
                                              ][choice.id][
                                                "button_ref"
                                              ] as RefObject<HTMLIonRadioElement>
                                            }
                                            color={
                                              choice.is_error
                                                ? "danger"
                                                : choice.is_success
                                                ? "success"
                                                : "primary"
                                            }
                                            value={choice.id}
                                            slot="start"
                                          ></IonRadio>
                                        </IonItem>
                                      );
                                    }
                                  )}
                                </IonRadioGroup>

                                {criteriaState &&
                                  `${c.id}` in criteriaState &&
                                  criteriaState[`${c.id}`] &&
                                  c.correctiveActions.length > 0 && (
                                    <IonItem>
                                      <IonLabel position="stacked">
                                        Acción Correctiva
                                      </IonLabel>
                                      <IonSelect
                                        {...registerReception(
                                          `criteria_${c.id}_corrective`
                                        )}
                                        onIonChange={(evt) => {
                                          setValueReception(
                                            `criteria_${c.id}_corrective`,
                                            evt.detail.value
                                          );
                                        }}
                                      >
                                        {c.correctiveActions.map(
                                          (
                                            ca: FWRegisterCriteriaCorrectiveAction
                                          ) => {
                                            return (
                                              <IonSelectOption
                                                key={ca.id}
                                                value={ca.id}
                                              >
                                                <IonLabel>{ca.name}</IonLabel>
                                              </IonSelectOption>
                                            );
                                          }
                                        )}
                                      </IonSelect>
                                    </IonItem>
                                  )}
                                <IonItem>
                                  <IonLabel position="stacked">
                                    Comentarios
                                  </IonLabel>
                                  <IonInput
                                    {...registerReception(
                                      `criteria_${c.id}_comments`
                                    )}
                                    type="text"
                                    placeholder={
                                      !readOnly ? "Ingrese sus comentarios" : ""
                                    }
                                  ></IonInput>
                                </IonItem>

                                <ErrorMessage
                                  errors={errors}
                                  name={`criteria_${c.id}`}
                                  render={({ message }) => (
                                    <IonItem>
                                      <IonText style={{ color: "red" }}>
                                        {message}
                                      </IonText>
                                    </IonItem>
                                  )}
                                />

                                <ErrorMessage
                                  errors={errors}
                                  name={`criteria_${c.id}_corrective`}
                                  render={({ message }) => (
                                    <IonItem>
                                      <IonText style={{ color: "red" }}>
                                        {message}
                                      </IonText>
                                    </IonItem>
                                  )}
                                />

                                <ErrorMessage
                                  errors={errors}
                                  name={`criteria_${c.id}_comments`}
                                  render={({ message }) => (
                                    <IonItem>
                                      <IonText style={{ color: "red" }}>
                                        {message}
                                      </IonText>
                                    </IonItem>
                                  )}
                                />
                              </IonCard>
                            </IonCol>
                          </IonRow>
                        );
                      })}
                    </IonGrid>
                  </form>
                </IonCardContent>
              </IonCard>
            </IonContent>
          </>
        )}
    </IonPage>
  );
};

export default RegisterRecordCollectProducts;
