/* eslint-disable @typescript-eslint/no-unused-vars */
import "./NewRegisterRecordForm.css";

import { format } from "date-fns";
import {
  anyToFWRegisterFormat,
  FWPOES,
  FWRegisterCriteria,
  FWRegisterFormat
} from "foodware-lib";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { FWBackendApi } from "../services/FWBackendApi";

export interface NewRegisterRecordProps {
  closeHandler?: () => void;
  newRegisterEntryCreateHandler?: (
    registerData: Record<string, unknown>
  ) => void;
}

const defaultCloseHandler = () => {
  console.log("Calling default close handler");
};

const defaultRegisterEntryCreateHandler = (data: Record<string, unknown>) => {
  console.log(
    "Calling default register entry creation handler with data: " + data
  );
};

const NewRegisterRecordForm: React.FC<NewRegisterRecordProps> = ({
  closeHandler = defaultCloseHandler,
  newRegisterEntryCreateHandler = defaultRegisterEntryCreateHandler,
}) => {
  const {
    data: registerFormatsDataRaw,
    isFetching: registerFormatsIsFetching,
    isSuccess: registerFormatsIsSuccess,
    isError: registerFormatsIsError,
    refetch: registerFormatsRefetch,
  } = FWBackendApi.useGetAllRegisterFormatsQuery({});

  const [isFormatLoading, setIsFormatLoading] = useState(false);
  useEffect(() => {
    if (registerFormatsIsError) {
      setIsFormatLoading(false);
    } else if (!registerFormatsIsSuccess && registerFormatsIsFetching) {
      setIsFormatLoading(true);
    } else if (registerFormatsIsSuccess) {
      setIsFormatLoading(false);
    }
  }, [
    registerFormatsIsFetching,
    registerFormatsIsSuccess,
    registerFormatsIsError,
  ]);

  const registerFormatsData = (registerFormatsDataRaw || []).map(
    anyToFWRegisterFormat
  );

  const [currentStep, _] = useState(0);
  const makeNewRegisterTitle = (r: FWRegisterFormat) => {
    const newRegisterDateTimeString = format(new Date(), "dd/MM/yy-HH:mm");
    const newRegisterTitle = `${r.code} ${newRegisterDateTimeString}`;
    return newRegisterTitle;
  };

  // * Make sure to select only unique POES
  const availablePOES: FWPOES[] = Array.from<FWPOES>(
    new Set((registerFormatsData || []).map((rf: FWRegisterFormat) => rf.poes))
  )
    .sort((a: FWPOES, b: FWPOES) => a.code.localeCompare(b.code))
    .filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

  const formatsByPOES: Record<
    string,
    { poes: FWPOES; formats: FWRegisterFormat[] }
  > = {};
  if (availablePOES) {
    for (const poes of availablePOES) {
      formatsByPOES[poes.name] = {
        poes: poes,
        formats: [],
      };
    }

    for (const registerFormat of registerFormatsData) {
      formatsByPOES[registerFormat.poes.name].formats.push(registerFormat);
    }
  }

  return (
    <IonPage>
      <IonLoading backdropDismiss={true} isOpen={isFormatLoading}></IonLoading>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={closeHandler}>
              <IonIcon slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>Nuevo ingreso de registro</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={"ion-padding"}>
        <IonRefresher
          slot="fixed"
          onIonRefresh={async (evt) => {
            await registerFormatsRefetch();
            evt.detail.complete();
          }}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {currentStep === 0 && (
          <IonCard>
            <IonCardContent>
              <IonList>
                {registerFormatsData &&
                  availablePOES &&
                  availablePOES.map((p: FWPOES) => {
                    const poesFormats = formatsByPOES[p.name].formats;
                    return (
                      <IonItemGroup key={p.id}>
                        <IonItemDivider></IonItemDivider>
                        {poesFormats.map((r: FWRegisterFormat, idx) => {
                          return (
                            <IonItem
                              key={idx}
                              button
                              detail={true}
                              lines="none"
                              onClick={() => {
                                newRegisterEntryCreateHandler({
                                  registerFormatId: r.id,
                                  title: makeNewRegisterTitle(r),
                                });
                              }}
                            >
                              <IonLabel style={{ whiteSpace: "normal" }}>
                                <IonText style={{ fontWeight: 700 }}>
                                  {r.name}
                                </IonText>
                                <h2>{r.code}</h2>
                                <h2>(versión {r.version})</h2>
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonItemGroup>
                    );
                  })}
              </IonList>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default NewRegisterRecordForm;
