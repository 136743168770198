import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch } from "../../store";

import type { NotificationData } from "./types";

export interface OfferCreatedNotificationData extends NotificationData {
  offerId: number;
}

export const offerCreatedNotification = createAsyncThunk<
  OfferCreatedNotificationData,
  OfferCreatedNotificationData,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  "notification/offer_created",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (notificationData: OfferCreatedNotificationData, _) => {
    return notificationData;
  }
);
