/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Archived.css";

import {
  anyToFWRegisterRecord,
  anyToFWUserProfile,
  FWRegisterFormat,
  FWRegisterRecord,
  FWUserProfile
} from "foodware-lib";
import * as O from "fp-ts/Option";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonLoading,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTitle,
  IonToolbar,
  useIonModal
} from "@ionic/react";

import {
  RegisterRecordCollectWrapper,
  RegisterRecordCollectWrapperProps
} from "../components/RegisterRecordCollect";
import { logoutUser } from "../features/user/userActions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { FWBackendApi } from "../services/FWBackendApi";
import { FWProfileApi } from "../services/FWProfileApi";

const ReadRegisterCollectModal = ({
  registerRecordCollectWrapperProps,
}: {
  registerRecordCollectWrapperProps: RegisterRecordCollectWrapperProps;
}) => <RegisterRecordCollectWrapper {...registerRecordCollectWrapperProps} />;

import type { RootState } from "../store";
const Archived: React.FC = () => {
  const dispatch = useAppDispatch();

  const { userInfo, loggedIn } = useAppSelector(
    (state: RootState) => state.user
  );
  const userId = O.isSome(userInfo) ? userInfo.value["pk"] : null;

  const {
    data: rawProfileData,
    isFetching: profileIsFetching,
    isSuccess: profileIsSuccess,
    isError: profileIsError,
  } = FWProfileApi.endpoints.getProfileByUserId.useQuery(userId, {
    skip: !loggedIn,
  });

  console.log("rawProfileData:");
  console.log(rawProfileData);

  const profileData: FWUserProfile =
    rawProfileData && anyToFWUserProfile(rawProfileData);

  console.log("ProfileData:");
  console.log(profileData);

  const performLogout = async () => {
    console.log("Logging out");
    dispatch(logoutUser({}));
  };

  const [isProfileLoading, setIsProfileLoading] = useState(false);
  useEffect(() => {
    if (profileIsError) {
      setIsProfileLoading(false);
    } else if (!profileIsSuccess && profileIsFetching) {
      setIsProfileLoading(true);
    } else if (profileIsSuccess) {
      setIsProfileLoading(false);
    }
  }, [profileIsFetching, profileIsSuccess, profileIsError]);

  const [recordsPage, setRecordsPage] = useState(1);

  const {
    data: registerRecordsDataRaw,
    isFetching: recordsIsFetching,
    isLoading: recordsIsLoading,
    isSuccess: recordsIsSucces,
    isError: recordsIsError,
    refetch: registerRecordsRefetch,
  } = FWBackendApi.useGetArchivedRegisterRecordsQuery(recordsPage);

  const recordsCount =
    (registerRecordsDataRaw && registerRecordsDataRaw?.count) || 0;

  const recordsTotalPages = Math.ceil(recordsCount / 10);

  const [isRecordsLoading, setIsRecordsLoading] = useState(false);
  useEffect(() => {
    if (recordsIsError) {
      setIsRecordsLoading(false);
    } else if (!recordsIsSucces && recordsIsFetching) {
      setIsRecordsLoading(true);
    } else if ((recordsIsLoading || recordsIsFetching) && recordsPage > 1) {
      setIsRecordsLoading(true);
    } else if (recordsIsSucces) {
      setIsRecordsLoading(false);
    }
  }, [recordsIsFetching, recordsIsLoading, recordsIsSucces, recordsIsError]);

  const registerRecordsData: FWRegisterRecord[] = (
    registerRecordsDataRaw?.results || []
  ).map(anyToFWRegisterRecord);

  console.log("registerRecordsData");
  console.log(registerRecordsData);

  const [selectedRegisterRecord, setSelectedRegisterRecord] =
    useState<FWRegisterRecord>();

  /* Reselect register record on data update, to refresh person list UI */
  useEffect(() => {
    if (selectedRegisterRecord) {
      const reselectedRegisterRecord = registerRecordsData.find(
        (v: FWRegisterRecord) => v.id === selectedRegisterRecord.id
      );
      if (reselectedRegisterRecord)
        setSelectedRegisterRecord(reselectedRegisterRecord);
    }
  }, [registerRecordsDataRaw]);

  const [selectedRegisterFormat, setSelectedRegisterFormat] =
    useState<FWRegisterFormat>();

  const [presentReadCollectModal, dismissReadCollectModal] = useIonModal(
    ReadRegisterCollectModal,
    {
      registerRecordCollectWrapperProps: {
        readOnly: true,
        closeHandler: () => dismissReadCollectModal(null, undefined),
        registerRecordId: selectedRegisterRecord?.id,
        registerFormat: selectedRegisterFormat,
      },
    }
  );

  return (
    <>
      <IonMenu contentId="registers">
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonMenuToggle>
              <IonItem onClick={performLogout}>
                <IonLabel>Cerrar Sesión</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>

      <IonPage id="registers">
        <IonLoading
          backdropDismiss={false}
          isOpen={isProfileLoading || isRecordsLoading}
        ></IonLoading>

        <IonRefresher
          slot="fixed"
          onIonRefresh={async (evt) => {
            await registerRecordsRefetch();
            evt.detail.complete();
          }}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Registros Archivados</IonTitle>
          </IonToolbar>
        </IonHeader>
        {!isProfileLoading && (
          <>
            <IonContent fullscreen className="ion-padding">
              {(registerRecordsData || []).length > 0 && (
                <>
                  <IonCard>
                    <IonCardHeader>
                      <IonText style={{ fontWeight: 700, color: "black" }}>
                        Tienes {recordsCount} registros archivados
                      </IonText>
                    </IonCardHeader>
                  </IonCard>

                  <IonCard>
                    <IonItemGroup>
                      {registerRecordsData &&
                        registerRecordsData.map((r: FWRegisterRecord) => {
                          return (
                            <>
                              <IonItem
                                key={r.id}
                                button
                                detail={true}
                                lines="none"
                                onClick={() => {
                                  setSelectedRegisterRecord(r);
                                  setSelectedRegisterFormat(r.registerFormat);
                                  presentReadCollectModal();
                                }}
                              >
                                <IonLabel style={{ whiteSpace: "normal" }}>
                                  <IonText style={{ fontWeight: 700 }}>
                                    #{r.id} {r.title}
                                  </IonText>
                                  <h2>{r.registerFormat.name}</h2>
                                  <h2>(versión {r.registerFormat.version})</h2>
                                  {r?.entriesCount !== undefined && (
                                    <h2>
                                      {r.entriesCount} entradas ingresadas
                                    </h2>
                                  )}
                                </IonLabel>
                              </IonItem>
                              <IonItemDivider></IonItemDivider>
                            </>
                          );
                        })}
                    </IonItemGroup>
                  </IonCard>
                </>
              )}
              {(registerRecordsData || []).length === 0 && (
                <>
                  <IonCard>
                    <IonCardContent>
                      <IonText>
                        Aún no tienes tomas de registro archivadas
                      </IonText>
                    </IonCardContent>
                  </IonCard>
                  <IonImg
                    src="assets/images/undraw_no_data.png"
                    style={{ margin: "auto" }}
                  />
                </>
              )}
            </IonContent>
            {recordsCount > 0 && (
              <IonFooter>
                <>
                  <IonToolbar
                    style={{ "--background": "white", color: "inherit" }}
                    className="ion-text-center"
                  >
                    <IonText style={{ color: "black" }}>
                      Viendo {1 + (recordsPage - 1) * 10} al{" "}
                      {Math.min(recordsPage * 10, recordsCount)} de{" "}
                      {recordsCount}
                    </IonText>
                    <IonButtons slot="start">
                      <IonButton
                        style={recordsPage == 1 ? { visibility: "hidden" } : {}}
                        disabled={recordsPage == 1}
                        onClick={() => setRecordsPage(recordsPage - 1)}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={chevronBackOutline}
                        ></IonIcon>
                      </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                      <IonButton
                        style={
                          recordsPage == recordsTotalPages
                            ? { visibility: "hidden" }
                            : {}
                        }
                        disabled={recordsPage === recordsTotalPages}
                        onClick={() => setRecordsPage(recordsPage + 1)}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={chevronForwardOutline}
                        ></IonIcon>
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </>
              </IonFooter>
            )}
          </>
        )}
      </IonPage>
    </>
  );
};

export default Archived;
