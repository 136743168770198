import "@ionic/react/css/core.css";

import { close } from "ionicons/icons";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";

export interface FWLoginData {
  username: string;
  email: string;
  password: string;
}
export interface FWLoginProps {
  className?: string;
  pageClassName?: string;
  closeHandler?: () => void;
  loginHandler?: (loginData: FWLoginData) => void;
}

const defaultCloseHandler = () => {
  console.log("Default close handler, please override");
};

const defaultLoginHandler = (loginData: FWLoginData) => {
  console.log("Default login handler, got login data:");
  console.log(loginData);
};

const formSchema = yup.object({
  username: yup
    .string()
    .required("Ingrese su nombre de usuario o correo electrónico."),
  password: yup.string().required("Ingrese su password."),
});

export const FWLogin: React.FC<FWLoginProps> = ({
  className,
  pageClassName,
  closeHandler = defaultCloseHandler,
  loginHandler = defaultLoginHandler,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleLoginCall = (data: FieldValues) => {
    console.log("Handling login button click; calling loginHandler");
    console.log(data);
    loginHandler({
      username: data["username"],
      email: "",
      password: data["password"],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={className}>
          <IonButtons slot="start">
            <IonButton onClick={closeHandler}>
              <IonIcon slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        className={`ion-padding ion-text-center ${pageClassName} bg-image`}
        scrollY={false}
      >
        <IonCard>
          <form onSubmit={handleSubmit(handleLoginCall)}>
            <IonGrid className={className}>
              <IonRow style={{ paddingTop: "0%" }}>
                <IonText
                  style={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "130%",
                    padding: "8px",
                  }}
                >
                  Inicia sesión
                </IonText>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      type="text"
                      {...register("username")}
                      value={getValues("username")}
                      onIonChange={(e) =>
                        e.detail.value && setValue("username", e.detail.value)
                      }
                    ></IonInput>
                    <ErrorMessage
                      className="ion-text-left"
                      errors={errors}
                      name="username"
                      as={<div style={{ color: "red" }} />}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                      type="password"
                      {...register("password")}
                      value={getValues("password")}
                      onIonChange={(e) =>
                        e.detail.value && setValue("password", e.detail.value)
                      }
                    ></IonInput>
                    <ErrorMessage
                      className="ion-text-left"
                      errors={errors}
                      name="password"
                      as={<div style={{ color: "red" }} />}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {" "}
                  <IonButton
                    className={"login-button"}
                    expand="block"
                    type="submit"
                    disabled={!isValid}
                  >
                    Iniciar Sesión
                  </IonButton>
                  {/* <p style={{ fontSize: "small", textAlign: "left" }}>
                    ¿No tienes una cuenta aún?{" "}
                    <a onClick={registerClickHandler}>Regístrate</a>
                  </p> */}
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
