import "./Home.css";

import {
  anyToFWRegisterFormat,
  anyToFWRegisterRecord,
  anyToFWUserProfile,
  FWRegisterFormat,
  FWRegisterRecord,
  FWUserProfile
} from "foodware-lib";
import * as O from "fp-ts/Option";
import React, { useEffect, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonModal
} from "@ionic/react";

import NewRegisterRecordForm, { NewRegisterRecordProps } from "../components/NewRegisterRecordForm";
import {
  RegisterRecordCollectWrapper,
  RegisterRecordCollectWrapperProps
} from "../components/RegisterRecordCollect";
import { logoutUser } from "../features/user/userActions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { FWBackendApi } from "../services/FWBackendApi";
import { FWProfileApi } from "../services/FWProfileApi";

import type { RootState } from "../store";

const NewRegisterModal = ({
  registerEntryProps,
}: {
  registerEntryProps: NewRegisterRecordProps;
}) => <NewRegisterRecordForm {...registerEntryProps} />;

const NewRegisterCollectModal = ({
  registerRecordCollectWrapperProps,
}: {
  registerRecordCollectWrapperProps: RegisterRecordCollectWrapperProps;
}) => <RegisterRecordCollectWrapper {...registerRecordCollectWrapperProps} />;

const Home: React.FC = () => {
  const dispatch = useAppDispatch();

  const { userInfo, loggedIn } = useAppSelector(
    (state: RootState) => state.user
  );
  const userId = O.isSome(userInfo) ? userInfo.value["pk"] : null;

  const {
    data: rawProfileData,
    error: _profileError,
    isError: profileIsError,
    isFetching: profileIsFetching,
    isSuccess: profileIsSuccess,
  } = FWProfileApi.endpoints.getProfileByUserId.useQuery(userId, {
    skip: !loggedIn,
  });

  const [isProfileLoading, setIsProfileLoading] = useState(false);
  useEffect(() => {
    if (profileIsError) {
      setIsProfileLoading(false);
    } else if (!profileIsSuccess && profileIsFetching) {
      setIsProfileLoading(true);
    } else if (profileIsSuccess) {
      setIsProfileLoading(false);
    }
  }, [profileIsFetching, profileIsSuccess, profileIsError]);

  console.log("rawProfileData:");
  console.log(rawProfileData);

  const profileData: FWUserProfile =
    rawProfileData && anyToFWUserProfile(rawProfileData);

  console.log("ProfileData:");
  console.log(profileData);

  // const profilePerson = profileData?.person;
  // const profileFullname = `${profilePerson?.firstName} ${profilePerson?.lastName}`;

  const performLogout = async () => {
    console.log("Logging out");
    dispatch(logoutUser({}));
  };

  const [createRegisterRecord] = FWBackendApi.useCreateRegisterRecordMutation();

  const [selectedRegisterRecord, setSelectedRegisterRecord] =
    useState<FWRegisterRecord>();
  const [selectedRegisterFormat, setSelectedRegisterFormat] =
    useState<FWRegisterFormat>();

  const [collectRegisterRecordEntry] =
    FWBackendApi.useCollectRegisterRecordEntryMutation();

  const [presentNewCollectModal, dismissNewCollectModal] = useIonModal(
    NewRegisterCollectModal,
    {
      registerRecordCollectWrapperProps: {
        closeHandler: () => dismissNewCollectModal(null, undefined),
        newRegisterRecordEntryCreateHandler: (
          data: Record<string, unknown>
        ) => {
          console.log("New entry with data: ");
          console.log(data);
          return collectRegisterRecordEntry(data).unwrap();
        },
        registerRecordId: selectedRegisterRecord?.id,
        registerFormat: selectedRegisterFormat,
      },
    }
  );

  const [
    getRegisterRecordById,
    {
      isFetching: recordIsFetching,
      isError: recordIsError,
      isSuccess: recordIsSuccess,
    },
  ] = FWBackendApi.useLazyGetRegisterRecordByIdQuery();

  const [
    getRegisterFormatById,
    {
      isFetching: formatIsFetching,
      isError: formatIsError,
      isSuccess: formatIsSuccess,
    },
  ] = FWBackendApi.useLazyGetRegisterFormatByIdQuery();

  const [presentNewRegisterModal, dismissNewRegisterModal] = useIonModal(
    NewRegisterModal,
    {
      registerEntryProps: {
        newRegisterEntryCreateHandler: (data: Record<string, unknown>) => {
          createRegisterRecord(data)
            .unwrap()
            .then((response) => {
              getRegisterRecordById(response.id)
                .unwrap()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((recordAny: any) => {
                  console.log("recordAny");
                  console.log(recordAny);
                  const registerRecord: FWRegisterRecord =
                    anyToFWRegisterRecord(recordAny);
                  setSelectedRegisterRecord(registerRecord);

                  getRegisterFormatById(registerRecord.registerFormat.id)
                    .unwrap()
                    .then(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (formatAny: any) => {
                        console.log("formatAny:");
                        console.log(formatAny);
                        const registerFormat: FWRegisterFormat =
                          anyToFWRegisterFormat(formatAny);

                        console.log("loaded register format");
                        console.log(registerFormat);
                        setSelectedRegisterFormat(registerFormat);

                        dismissNewRegisterModal(null, undefined);
                        presentNewCollectModal();
                      }
                    );
                })
                .catch((error) => {
                  console.error(error);
                  dismissNewRegisterModal(null, undefined);
                });
            })
            .catch((error) => {
              console.error(error);
              dismissNewRegisterModal(null, undefined);
            });
        },
        closeHandler: () => dismissNewRegisterModal(null, undefined),
      },
    }
  );

  const [isRecordLoading, setIsRecordLoading] = useState(false);
  useEffect(() => {
    if (recordIsError) {
      setIsRecordLoading(false);
    } else if (!recordIsSuccess && recordIsFetching) {
      setIsRecordLoading(true);
    } else if (recordIsSuccess) {
      setIsRecordLoading(false);
    }
  }, [recordIsFetching, recordIsSuccess, recordIsError]);

  const [isFormatLoading, setIsFormatLoading] = useState(false);
  useEffect(() => {
    if (formatIsError) {
      setIsFormatLoading(false);
    } else if (!formatIsSuccess && formatIsFetching) {
      setIsFormatLoading(true);
    } else if (formatIsSuccess) {
      setIsFormatLoading(false);
    }
  }, [formatIsFetching, formatIsSuccess, formatIsError]);

  return (
    <>
      <IonMenu contentId="home">
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList>
            <IonMenuToggle>
              <IonItem onClick={performLogout}>
                <IonLabel>Cerrar Sesión</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>

      <IonPage id="home">
        <IonLoading
          backdropDismiss={true}
          isOpen={isProfileLoading || isRecordLoading || isFormatLoading}
        ></IonLoading>

        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Inicio</IonTitle>
          </IonToolbar>
        </IonHeader>
        {!isProfileLoading && (
          <IonContent fullscreen className="ion-padding">
            <IonImg
              src="assets/images/undraw_articles.png"
              style={{ margin: "auto" }}
            />
            <IonButton onClick={() => presentNewRegisterModal()} expand="full">
              Ingresar Nuevo Registro
            </IonButton>
          </IonContent>
        )}
      </IonPage>
    </>
  );
};

export default Home;
