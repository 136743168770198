import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  API_BASE_URL,
  defaultPrepareHeadersAuth,
  makeBaseQueryWithReauthAndRetry
} from "./common";

export const FWProfileApi = createApi({
  reducerPath: "FWProfileApi",
  baseQuery: makeBaseQueryWithReauthAndRetry(
    fetchBaseQuery({
      baseUrl: API_BASE_URL,
      prepareHeaders: defaultPrepareHeadersAuth,
    })
  ),
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    getProfileByUserId: builder.query({
      query: (userId) => `/profiles/byUserId/${userId}/`,
      providesTags: ["Profile"],
    }),
    getProfileByFWUserProfileId: builder.query({
      query: (fwUserProfileId) => `/profiles/byProfileId/${fwUserProfileId}/`,
      providesTags: ["Profile"],
    }),
  }),
});
