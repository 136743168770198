import "./RegisterSuccess.css";

import { close } from "ionicons/icons";
import React from "react";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar
} from "@ionic/react";

// import { OfferCreatedNotificationData } from "../features/notifications/notificationsActions";
import { NotificationData, NotificationUserFlow } from "../features/notifications/types";

const NotificationPage: React.FC<{
  userFlow: NotificationUserFlow;
  notificationData: NotificationData;
  closeHandler: () => void;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}> = ({ userFlow, notificationData, closeHandler }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={closeHandler}>
              <IonIcon slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className={"ion-padding ion-text-center register-success-page"}
        fullscreen
        scrollY={false}
      >
        {/* {userFlow === NotificationUserFlow.OFFER_CREATED && (
          <OfferCreatedNotificationContent
            rootCloseHandler={closeHandler}
            notificationData={notificationData as OfferCreatedNotificationData}
          />
        )} */}
      </IonContent>
    </IonPage>
  );
};

export default NotificationPage;
